import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import Aux from '../../hoc/Auxiliary'
import './appAdvertisement.css'
class appAdvertisement extends Component {
    render() {
        return (
            <Aux>
            <NavLink to='/contact'> <FaArrowLeft style={{ color: 'white', size: '100px' }} /></NavLink>   
            <div className ="mt-5 appad">
                <h5>
                    google.com, pub-9936723175818632, DIRECT, f08c47fec0942fa0
                    <br />
                    triplelift.com, 9726, DIRECT, 6c33edb13117fd86
                    <br />
                    triplelift.com, 9726-EB, DIRECT, 6c33edb13117fd86
                    <br />
                    adingo.jp, 24319, DIRECT
                    <br />
                    appnexus.com, 7044, RESELLER, f5ab79cb980f11d1
                    <br />
                    openx.com, 540679900, RESELLER, 6a698e2ec38604c6
                    <br />
                    webeyemob.com, 70080, RESELLER
                    <br />
                    admixer.net, 3bc509b2-6568-4bd8-a997-9859ba0c9118, RESELLER
                    <br />
                    adcolony.com, 801e49d1be83b5f9, RESELLER, 1ad675c9de6b5176
                    <br />
                    pubmatic.com, 157962, RESELLER, 5d62403b186f2ace
                    <br />
                    pubmatic.com, 158853, RESELLER, 5d62403b186f2ace
                    <br />
                    pubmatic.com, 158060, RESELLER, 5d62403b186f2ace
                    <br />
                    pubmatic.com, 159856, DIRECT, 5d62403b186f2ace
                    <br />
                    indexexchange.com, 193418, DIRECT
                </h5>
            </div>
            </Aux>
        );
    }
}

export default appAdvertisement ;