import React , {Component} from 'react';
import { NavLink } from 'react-router-dom';
import Aux from '../../hoc/Auxiliary';
import { motion } from "framer-motion";
import contact from '../../Assets/Images/contact.svg';
import location from '../../Assets/Images/location.svg';
import { FaArrowLeft } from "react-icons/fa";
import '../Contact/Contact.css';
import './termsConditions.css'

class gametermsConditions extends Component {
    render(){
        const newcontactpolicy =
        <Aux>
            {/* <NavLink to={this.props.location.state.from}><FaArrowLeft style={{ color: 'white', size: '100px' }} /></NavLink> */}
            <NavLink to='/Contact'><FaArrowLeft style={{ color: 'white', size: '100px' }} /></NavLink>

 <div className="row mt-5">
                <div className="col-md-8  col-sm-5 mt-5 d-flex masterdiv">
                    <div className="newcontent martc20">

                        <h1>FOOTBALL WORLD - Terms of Service</h1>


                        <h4>
                        FOOTBALL WORLD - Terms of Service
                    </h4>


                        <p>
                            
                        Please read these before playing our Games!  
                     <br/>   Effective Date: November 10, 2022  
                     <br/> The terms of this agreement ("Terms of Service") govern the relationship between you and "Audify Tech Pvt. Ltd." having its registered office at Prestige Technostar, Building 3, Level 8, Whitefield, Bengaluru 560048 (from now on "Audify Tech" or "Us" or "We" or “Our”) regarding your use of our games, websites and related services. Audify Tech’s Privacy Policy and other relevant policies govern the use of our service, which are comprised herein by reference.  
                     <br/>Before accessing or using our service, including browsing Audify Tech’s website or accessing a game, you must agree to these Terms of Service and the Privacy Policy. You may also be required to register an account on the service. Registering for an account or using the service conveys that you are 13 or older. If you are between 13 and 18, you represent that your legal guardian has reviewed and agreed to these Terms. If you access our service from a Social Networking Site ("SNS"), such as Facebook or Google+, you shall concede with its terms of service/use and our Terms of Service.  
                     <br/>By installing, utilizing, or otherwise accessing the service, you agree to these terms of service. If you do not agree to these terms of service, please do not install, use, or otherwise access the service. Use of the service is void where restricted. 
                     <br/>Audify Tech reserves the right, at its discretion, to change, alter, add or dismiss portions of these Terms of Service, its Privacy Policy, and other relevant Audify Tech’s policies at any time by posting the amended terms on the Audify Tech service. You would have to accept such changes by resuming the service. If at any point you do not consent to any portion of the then-current version of our Terms of Service, the Audify Tech Privacy Policy, or any other Audify Tech’s policy, rules, or codes of conduct linking to your use of the service, your license to use the service shall instantly end, and you must directly stop using the service.  
                    </p>



                        <h4>
                            1. License  
                    </h4>


                        <p>
                        1.1. Grant of a Limited License to Use the Service  
                        <br/>Subject to your agreement and continuing compliance with these Terms of Service and any other relevant Audify Tech’s policies, Audify Tech grants you a non-exclusive, non-transferable, non-sublicensable, revocable, and limited license to access and use the service for your non-commercial entertainment purposes. You agree not to use our service for any other purpose.  
                        <br/>The following restrictions apply to the use of our service:  
                        <br/>You shall not create an account or access the service if you are under the age of 13; You shall restrict use by minors and deny access to children under the age of 13. You accept full responsibility for any unauthorized use of the services by minors. You are responsible for using your debit/credit card or other payment instruments (e.g., PayPal) by children.  
                        <br/>You shall not (or attempt to) purchase, sell, rent or give away your account, create an account using a false identity or information, or on behalf of someone other than yourself. You shall not use our service if we previously have banned or removed you from playing any of our games.  
                        <br/>You shall use your account only for non-commercial purposes. You shall not use our service to advertise, solicit, or transmit any commercial advertisements, including chain letters, junk or spam e-mail, or repetitive or misleading messages to anyone.  
                        <br/>Login information and Your Account  
                        <br/>You may be required to select a password for your account or use other credentials to access the account ("Login Information"). You shall not share the account or login information, nor let anyone else access your account or do anything else that might jeopardize the security of your account. In case you become aware of or reasonably suspect any security breach, including any loss, theft, or unauthorized disclosure of the Login information. In that case, you must immediately notify Audify Tech and modify your login information. You are solely responsible for maintaining the confidentiality of the Login Information, and you will be responsible for all uses of the Login Information, including purchases, whether or not authorized by you. You are responsible for anything that happens through your account.  
                        <br/>Audify Tech reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to claims by a third party that a username violates the third party's rights.  
                        <br/>Our service supports only one account per game on a supported device.  
                        <br/>License Limitations  
                        <br/>Any use of the service in violation of these License Limitations is strictly prohibited, can immediately revoke your limited license, and may subject you to liability for law violations.  
                        <br/>You agree that you will not, under any circumstances: 
                        <p>
                            <ul>
                            <li>Engage in any act Audify Tech deems to conflict with the spirit or intent of our service or make improper use of Audify Tech support services. </li>
                        <li>Use or take part (directly or indirectly) in the use of cheats, exploits, automation software, bots, hacks, mods, or any unauthorized third-party software designed to modify or interfere with our service, any of our games, or its experience.  </li>
                        <li>Modify or cause to be modified files that are part of our service or our games without explicit written consent by Audify Tech.  </li>
                        <li>Disrupt, interfere with or otherwise adversely affect the normal flow of the service or act in a manner that may negatively affect other users' experience when using the service or playing our games. This effect includes win trading and any different kind of manipulation of rankings, taking advantage of errors in the service to gain an unfair edge over other players, and any other act that intentionally abuses or goes against the design of our service.  </li>
                        <li>Disrupt, overburden, or assist in disrupting or overburdening any computer or server ("Server") used to offer or support our service or any of our game environments.  </li>
                        <li>Institute, assist, or become involved in any attack, including without limitation distribution of a virus, denial of service attacks upon the service, or other attempts to disrupt the service or any other person's use or enjoyment of our service.  </li>
                        <li>Attempt to gain unauthorized access to the service, accounts registered to others, or the computers, servers, or networks connected to the service by any means other than the user interface provided by Audify Tech. It also includes but is not limited to circumventing or modifying, attempting to avoid or alter, or encouraging any other person to circumvent or modify any security, technology, device, or software part of the service.  </li>
                        <li>Post any information that is abusive, threatening, obscene, defamatory, libelous, racially, sexually, religiously, or otherwise objectionable or offensive or engage in ongoing toxic behavior, such as by repeatedly posting information on an unsolicited basis.  </li>
                        <li>Post any information containing nudity, excessive violence, offensive subject matter, or a link to such content.  </li>
                        <li>Attempt to, or harass, abuse, harm, or advocate or incite harassment, misuse, or damage of another person, or group, including Audify Tech’s employees.  </li>
                        <li>Make available through the service any material or information that infringes any copyright, trademark, patent, trade secret, right of privacy or right of publicity of any person or entity or impersonates any other person, including without limitation any Audify Tech’s employee.  </li>
                        <li>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying software or other intellectual property used to provide the service or any of our games. Also, to obtain any information from our service or our games using any method not expressly permitted by us.  </li>
                        <li>Solicit or attempt to solicit login Information or any other login credentials or personal information from other users of our service or any of our games.  </li>
                        <li>Collect or post anyone's private information, including personally identifiable information (whether in text, image, or video form), identification documents, or financial information, through our service. We reserve the right to determine what conduct it considers to violate the rules of use or otherwise outside the intent or spirit of these Terms of Service or the service itself. We reserve the right to take action, including terminating your account and prohibiting you from using our service in whole or in part.  </li>
                            </ul>
                        </p> 
                       

<br/> 1.2. Suspension and Termination of Account and Service  
<br/>Without limiting any other remedies, Audify Tech may restrict, suspend, terminate, modify, or delete accounts or access to the service or portions. The suspension will happen if Audify Tech suspects you are, failing to comply with these terms of service or for any actual or suspected illegal or improper use of the service, with or without notice to you. You can lose your user name and persona in our service due to account termination or limitation, as well as any benefits, privileges, earned items, and purchased items associated with your use of the service. Audify Tech is under no obligation to compensate you for any such losses or results.  
<br/>Without limiting our other remedies, we may restrict, suspend or terminate the service and user accounts or portions thereof, prohibit access to our games and sites and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to prevent users from accessing the service if we believe that they are creating risk or possible legal liabilities, infringing the intellectual property rights of third parties, or acting inconsistently with the letter or spirit of our terms or policies. Additionally, in appropriate circumstances and at our sole discretion, we may suspend or terminate accounts of users who may be repeat infringers of third-party intellectual property rights.  
<br/>Audify Tech reserves the right to terminate any account that has been inactive for 180 days.  
<br/>Audify Tech reserves the right to stop offering and supporting our service of a particular game or part of our service at any time. At this point, we would automatically terminate your license to use the service or a portion thereof. In such an event, Audify Tech is not liable to provide refunds, benefits, or other compensation to users in connection with such discontinued service.  
T<br/>erminating your account can include disabling your access to our service or any part thereof, including any content you submitted or others submitted.  
<br/>You may terminate your account anytime and for any reason by following the process described on our support page located at www.audifytech.com/contact, informing Audify Tech that you wish to terminate your account.  
                    </p>
                        <h4>
                            2. Ownership 

                    </h4>


                        <p>
                        2.1. Games and Service  
                        <br/>All rights, titles, and interest in and to the service (including without limitation any games, titles, computer code, themes, assets, characters, animations, concepts, artwork, sounds, musical compositions, audio-visual effects, methods of operation, moral rights, documentation, in-game chat transcripts, character profile information, recordings of games played using our game client, and our game clients and server software are owned by us. Audify Tech reserves all rights, including, without limitation, all intellectual property rights or other proprietary rights, in connection with its games and the service.  
                        <br/>2.2. Accounts  
                        <br/>Notwithstanding anything to the contrary, you acknowledge and agree that you shall have no ownership or other property interest in the account. You further acknowledge and agree that all rights in and to your account shall forever be owned by and inure to the benefit of Audify Tech.  
                        <br/>2.3. Virtual Items  
                        <br/>Audify Tech owns licensed rights to use all of the content that appears in the service or our games. You also agree that you have no right in or to any content in the service, including the virtual goods or currency appearing or originating in our games, whether earned, purchased from us or any other associated attributes with an account.

                    </p>

                    <p>
                     You should also read our Privacy Policy. The Privacy Policy explains how we use your personal data.
                        If you wish to buy any virtual currency or items in our Services, you should read our Audify Virtual Items Terms and Conditions.
                        If you wish to buy any recurring subscriptions in our Services ("Subscriptions"), you should read our Subscription Terms and Conditions.
                        If you wish to link Audify Games to your website, you should read our Audify Webmaster Terms.                
                    </p>
                    


                        <h4>
                            3.  User Content 
                    </h4>


                        <p>
                        3.1. Submission of User Content  
                        <br/>"User Content" means any communications, images, sounds, and all the material, data, and information that you upload or transmit through our game client or the service or that other user upload or share, including without limitation any text chat/voice. By sending or submitting any User Content while using our service, you affirm, represent, and warrant that such transmission or submission is: 
                    <p>
                    <ol>
                     <li>Accurate and not confidential or misleading. </li>
                    <li>Not in violation of any laws, contractual restrictions, or other third-party rights, and that you have permission from any third party whose personal information or intellectual property is in the User Content. 
                     </li>
                    <li>Free of viruses, adware, spyware, worms, or other malicious code. </li>
                    <li>You acknowledge and agree that we will process your personal information within such content by following our Privacy Policy.  </li>
                    </ol>
                    </p>



<br/>3.1.1. Content Screening  
<br/>Audify Tech takes no responsibility for the conduct of any user submitting any User Content and assumes no responsibility for monitoring our service for inappropriate content or conduct. We do not, and cannot, pre-screen or monitor all User Content. Your use of our service is at your own risk. Using the service may expose you to User Content that is offensive, indecent, or otherwise not in line with your expectations. You bear all risks associated with using any User Content available in connection with the service. At our discretion, our workforce, associates, partners, or technology may monitor and record your interaction with the service or communications (including, without limitation, text chat/voice) when you are using our service.  
<br/>By entering these Terms of Service, you hereby provide your irrevocable consent to such monitoring and recording. You acknowledge and agree that you have no expectation of privacy concerning the transmission of any User Content, including, without limitation, chat text or voice communications.  
<br/>Audify Tech reserves the right in its sole discretion to monitor, prohibit, edit, delete, disable access to or otherwise make unavailable any User Content (including without limitation your User Content) without any reason at any time. Suppose at any time Audify Tech chooses, in its sole discretion, to monitor our service. In that case, we nonetheless assume no responsibility for User Content and assume no obligation to modify or remove any inappropriate User Content. We have the right, but we are not obliged, in our sole discretion, to edit, refuse to post, or remove any User Content.  
<br/>3.2. Information Use by Other Members of the Service  
<br/>3.2.1. Public Discourse  
<br/>Our service may include various forums, blogs, and chat features where you can post User Content, including your observations and comments on designated topics. Audify Tech cannot guarantee that other members will not use the ideas and information you share. Therefore, do not post it on our service if you have an idea or information you want to keep confidential and don't want others to use it. Audify Tech shall have no responsibility to evaluate, use or compensate you for any ideas or information you may choose to submit.  
<br/>3.2.2. Responsible For Your Content  
<br/>You are solely responsible for the information you post on through, or in connection with the service you share with others. Audify Tech may reject, refuse to post, or delete any User Content for any or no reason, including, but not limited to, User Content that violates these Terms of Service in the sole judgment of Audify Tech.  
<br/>3.2.3. Your License to Audify Tech  
<br/>You, at this moment, grant us an irrevocable, perpetual, transferable, fully paid-up, royalty-free, worldwide license (including the right to sublicense and assign to a third party) and the right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, broadcast, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, send to the public by telecommunication, exhibit, perform, enter into computer memory, and use and practice, in any way, your User Content as well as all modified and derivative works thereof in connection with the provision of our service, including marketing and promotions of the service.  
<br/>You also now grant Audify Tech the right to authorize others to exercise any rights granted to Audify Tech under these Terms of Service. With this grant to Audify Tech, you further the unconditional, irrevocable right to use and exploit your name, likeness, and any other information or material included in any User Content and connection with any User Content without any obligation to you. Except as prohibited by law, you waive any rights of attribution and any moral rights you may have in your User Content, regardless of whether your User Content is altered or changed. Audify Tech does not claim ownership rights in your User Content, and nothing in these Terms of Service intends to restrict any rights you may have to use and exploit. Audify Tech has no obligation to monitor or enforce your intellectual property rights in or to your User Content.  
<br/>3.3. User Interactions  
<br/>You are solely responsible for your interactions with other users of our service and any other parties with whom you interact through the service and our games. Audify Tech reserves the right but has no obligation to become involved in any way with these disputes. You will fully cooperate with Audify Tech to investigate any suspected unlawful, fraudulent, or improper activity, including, without limitation, granting Audify Tech access to any password-protected portions of your account.  
<br/>Suppose you have a dispute with one or more users. In that case, you release us (and our employees, directors, partners, subsidiaries, joint ventures, and employees) from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.  

                    </p>


                        <h4>
                            4. Fees and Purchase Terms 

                    </h4>

                  <p>  <br/> 4.1. Purchases  </p>

                        <p>
                      <br/>  In our service, you may purchase, with "real world" money, a limited, personal, non-transferable, non-sublicensable, revocable license to use: 
                
                <p>
                    <ol>
                        <li> "Virtual currency," including but not limited to virtual coins or gems, all for use in our games.</li>
                        <li>"Virtual in-game items" ("Virtual Items"). </li>
                        <li>Other goods or services ("Merchandise" including bags and football kits). </li>
                    </ol>
                </p>
<br/>You are only allowed to purchase Virtual Items from our authorized partners or us through our service and not in any other way.  
<br/>Audify Tech may manage, regulate, control, modify or eliminate Virtual Items and Merchandise at any time, with or without notice. Audify Tech shall have no liability to you or any third party if Audify Tech exercises any such rights.  
<br/>Other than as explicitly authorized in our service, you shall not sell, purchase, redeem or otherwise transfer Virtual in-game items or Merchandise to any person or entity or attempt any of those above, including but not limited to Audify Tech, another user, or any third party.  
<br/>All purchases and redemptions of virtual items made through the service are final and non-refundable.  
<br/>The provision of Virtual Items for use in our games is a service provided by Audify Tech that commences immediately upon acceptance by Audify Tech of your purchase.  
<br/>4.2. Payment of Fees  
<br/>You agree to pay all fees and applicable taxes incurred by you or anyone using a registered account. Audify Tech may revise the pricing for the goods and services offered through our service at any time. You acknowledge that Audify Tech is not required to provide a refund for any reason and that you will not receive money or other compensation for unused virtual items when an account is closed, whether such closure was voluntary or involuntary.  
                    </p>

                   

                        <h4>
                            5. Updates to the Service 
                    </h4>

                        <p>
                        You understand that our service is an evolving one. Audify Tech may require that you accept updates to the service and the games you have installed on your device or computer. You acknowledge and agree that Audify Tech may update the service and our games, with or without notifying you. You may need to update third-party software from time to time to receive the service and play our games.  

                          </p>



                        <h4>
                            6. Disclaimer of Warranties
                    </h4>

                        <p>
                        Without limiting Audify Tech's Liability under section 7 below, the service is provided on "as is" and "as available" basis for your use, without warranties of any kind, explicit or implied. Audify Tech do not warrant that you can access or use the service at the times or locations of your choosing. Also, the service will be uninterrupted or error-free; that defects will be corrected, or the game or service will be free of viruses or other harmful components.  
Some jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above disclaimers may not apply to you.  
                    </p>
                         
                        
                        
                        <h4>
                            7. Limitation of Liability, Sole and Exclusive Remedy and Indemnification  
                    </h4>

                        <p>
                        To the maximum extent permitted by law, Audify Tech shall not be liable to you for any indirect, incidental, consequential, special, punitive, or other similar damages, including but not limited to loss of revenues, lost profits, lost data, or business interruption or other intangible losses (however such losses are qualified), arising out of or relating in any way to these terms of service or the service itself, whether based on contract, tort or any other legal theory, and whether or not Audify Tech has been advised of the possibility of such damages. You acknowledge and agree that if you have not paid anything to Audify Tech, your sole remedy (and Audify Tech's exclusive liability) for any dispute with Audify Tech is to stop using the service and cancel your account.  
Nothing in these terms of service shall affect the statutory rights of any consumer or exclude or restrict any liability resulting from gross negligence or willful misconduct of Audify Tech or for death or personal injury arising from any negligence or fraud by Audify Tech.  
You agree to indemnify, defend and hold Audify Tech (and our employees, directors, partners, subsidiaries, or joint ventures) harmless from any claim, demand, damages, or other losses, including reasonable attorneys' fees, asserted by any third party resulting from or arising out of your use of the service, or any breach by you of these Terms of Service, however the previous does not apply if the infringement of rights is not attributable to your intentional or negligent behavior.  

                    </p>

                      
                         
                   <h4>
                  8. Dispute Resolution and Law
                    </h4>

                         <p>

                         If a dispute arises between you and Audify Tech, we strongly encourage you to contact us directly to seek a resolution by going to our customer support site at www.audifytech.com/contact. You agree that any claim or dispute against Audify Tech must be resolved exclusively by the laws of India and it shall govern all disputes between you and Audify Tech without regard to conflict of law provisions. You agree that any claim or dispute against Audify Tech must be resolved exclusively by a court in Bengaluru, India
                        </p>


                        <h4>
                        9. Severability  
                    </h4>

                        <p>
                        You and Audify Tech agree that if any portion of these Terms of Service or the Audify Tech’s Privacy Policy is found illegal or unenforceable, in whole or in part by any court of competent jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the extent of such determination of invalidity or unenforceability without affecting the validity or enforceability thereof in any other manner or jurisdiction and without affecting the remaining provisions of the terms, which shall continue to be in full force and effect.                           </p>

                        



                        <h4>
                            10.  General Provisions  
                    </h4>

                        <p>
                      <br/>  10.1. Assignment  
                      <br/>Audify Tech may assign or delegate these Terms of Service and the Audify Tech Privacy Policy, in whole or part, to any person or entity at any time, with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Policy without Audify Tech's prior written consent, and any unauthorized assignment and delegation by you are ineffective.  
                      <br/>10.2. Supplemental Policies  
                      <br/>Audify Tech may publish additional policies related to specific services such as forums, contests, or loyalty programs. Your right to use such services is subject to those specific policies and these Terms of Service.  
                      <br/>10.3. Entire Agreement  
                      <br/>These Terms of Service, any supplemental policies, and any documents expressly incorporated by reference herein (including the Audify Tech’s Privacy Policy) contain the entire understanding of you and Audify Tech and supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and us concerning the service.  
                      <br/>10.4. No Waiver  
                      <br/>The failure of Audify Tech to require or enforce strict performance by you of any provision of these Terms of Service or the Audify Tech’s Privacy Policy or failure to exercise any right under them shall not be taken as a waiver or relinquishment of Audify Tech's right to assert or rely upon any such provision or right in that or any other instance.  
                      <br/>The express waiver by Audify Tech of any provision, condition, or requirement of these Terms of Service or the Audify Tech’s Privacy Policy shall not constitute a waiver of any future obligation to comply with such provision, condition, or requirement.  
                      <br/>Except explicitly outlined in the Terms of Service, no representations, statements, consents, waivers, or other acts or omissions by Audify Tech shall be deemed a modification of these Terms of Service. It will not be legally binding unless documented in physical writing, hand signed by you and a duly appointed employee of Audify Tech.  
                      <br/>10.5. Notices  
                      <br/>We may notify you via postings on www.audifytech.com and via e-mail or any other communications means to contact information you provide to us. All notices given by you or required from you under these Terms of Service or the Audify Tech’s Privacy Policy shall be in writing and addressed to Audify Tech Pvt. Ltd. - Prestige Technostar, Building 3, Level 8, Whitefield, Bengaluru 560048. Any notices you provide without compliance with this Section on Notices shall have no legal effect.  
                      <br/>10.6. Equitable Remedies  
                      <br/>You acknowledge that the rights granted, and obligations made under these Terms of Service to Audify Tech are of a unique and irreplaceable nature, the loss of which shall irreparably harm Audify Tech and which cannot be replaced by monetary damages alone so that Audify Tech shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of damages) in the event of any breach or anticipatory breach by you.  
                      <br/>You irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of the service or any Audify Tech game, exploitation of any advertising or other materials issued in connection in addition to that, or exploitation of our service or any content or other material used or displayed through our service and agree to limit your claims to claims for monetary damages, limited by Section 7 (if any).  
                      <br/>10.7. Force Majeure  
<br/>Audify Tech shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of Audify Tech, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond Audify Tech' control like acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials. 
                    </p>


                          
                
                                
                        <h4 style={{
                            marginBottom :'1px'
                        }}>
                            Thank you,
                            </h4>
                    <h4>
                            Team Audify
                        </h4>


                    </div>
                </div>
                <div className="col-md-4 mt-5 col-sm-6 masterdiv232  ">
                    <img src={contact} className="contact  img-fluid" />
                    <div className="newcontent2">
                        <h3>
                            CONTACT US
                        </h3>
                        {/* <a style={{
                                    textDecoration: 'none',
                                    color: '#1CC8EE',
                                }}>
                                    hello@audifytech.com
                        </a> */}

                        <p>Audify, Level 8, Building 3, 
                                    <br />
                                     The Executive Center, Prestige Technostar, 
                                     <br />
                                     Whitefield, Bangalore , India
                                    <br />
                                    PIN - 560066,
                   </p>
                        <h3>
                            Locate us
                  </h3>
                        <a className="hi23" href="https://www.google.com/maps/place/Audify/@12.9740837,77.7086497,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1176c6c25ff5:0x2461cd938b4f5c0!8m2!3d12.9740825!4d77.7108343"><img className="locate22" src={location} alt="location"></img></a>
                    </div>
                </div>
            </div>
        </Aux>
        return(
            <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            {newcontactpolicy}
        </motion.div>
        );
    }
}

export default gametermsConditions ;