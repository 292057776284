import React from 'react'
import { motion } from "framer-motion";
import { useState } from "react";
import './Jobs.css'
import Job2 from '../../Assets/Images/jobs.png'
import { Drawer, makeStyles } from "@material-ui/core";
import Jobapply from '../JobApply/JobApply'


const useStyles = makeStyles({
    paper: {
        width: "70%",
        
    },
});

const Jobs = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [jobs, setJobs] = useState("");
    const [descriptions ,setDescriptions] = useState(null);
    const [qualifications ,setQualifications] = useState(null);
    const [responsibility ,setResponsibility] = useState("");

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            // className="Backdrop3"
        >

            {/* the part one is only the image and the description */}


            <div className="row mt-5">
                <div className="col-md-5 mt-5 ml-1 col-sm-4 d-flex justify-content-center ">
                    <img rel="preload" src={Job2} className="jobs  img-fluid" />
                </div>
                <div className="col-md-6 mt-5 col-sm-4 d-flex justify-content-start align-self-center ">
                    <div className="jobs-content">
                        <h1 >JOBS</h1>
                        <h6>
                            If you have reached this page we assume that you have liked us.
                     <br />
                      We have best minds on our team and want to hire more like you .
                     <br />
                      Apply now.
                  </h6>
                    </div>
                </div>
            </div>



            {/* to open slider from right ================== */}
            <Drawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                classes={{ paper: classes.paper }}
            >
                <Jobapply jobs={jobs} setOpen={setOpen} descriptions = {descriptions} qualifications ={qualifications} responsibility = {responsibility} />
            </Drawer>





            {/* the second part is for cards and the drawwers etc */}
            <div className="job-sectors mt-5">
                <h1 className="mb-5 text-center">Current openings</h1>


                <div className="row mt-5">
                    <div className="col-md-3 d-flex justify-content-center">
                        <div onClick = { ()=>{
                        let newqualifications =  <ul style={{
                        color: 'white',
                        fontSize: '18px',
                        lineHeight: '26px',
                        textDecoration: 'none',
                        marginTop: '20px',
                        marginBottom: '20px' }}
                        className="list-unstyled">
                            <li style = {{textDecoration :'none'}}>1. Bachelor's/Master's degree or equivalent experience in Business, Human Resources, or related area</li>
                            <li>2. 3+ years' of experience working in Human Resources</li>
                            <li>3. Strong interpersonal and communication skills</li>
                            <li>4. Ability to hustle between different roles</li>
                            <li>5. Work experience in a tech company is plus</li>
                          </ul>

                          let newdescriptions = <ol  style={{
                            color: 'white',
                            fontSize: '18px',
                            lineHeight: '26px',
                            marginTop: '20px',
                            marginBottom: '20px' }}
                            className="list-unstyled" className="list-unstyled">
                              <li>1. Oversee the full-cycle of recruitment efforts</li>
                              <li>2. Develop and oversee new hire orientation, onboarding efforts, and employee termination process</li>
                              <li>3. Assist with payroll and benefits administration</li>
                              <li>4. Building the right incentive structure for employees</li>
                              <li>5. Creating a conducive work environment and culture for innovation and creativity which attract talent</li>
                              <li>6. Contact references and perform background checks</li>
                              <li>7. Process paperwork</li>
                              <li>8. Ensures compliance with company policies and procedures and legal Responsibilities</li>
                          </ol>

                            
                            setJobs("RECRUITMENT MANAGER");
                            setResponsibility("The ideal candidate will be responsible for recruitment efforts, new hire orientation and onboarding, employee termination, payroll and benefits, company policy, and procedure adherence. In addition, you will build strong relationships with our company's managers in order to help them with their human resource needs.")
                            setQualifications(newqualifications);
                            setDescriptions(newdescriptions);
                            setOpen(true);
                        }} 
                        className="card" style={{ width: '18rem', marginBottom: '40px', background: '#1F2A45', color: 'white' }}>
                            <div class="card-body">
                                <h5 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', lineHeight: '16px' }} class="card-text">RECRUITMENT MANAGER</h5>
                                <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: '20px', fontSize: '14px', lineHeight: '15px', color: '#95A2C2' }}>3+ years</p>
                            </div>
                        </div>
                    </div>




                    <div className="col-md-3 d-flex justify-content-center">
                        <div onClick = { ()=>{
                             let newqualifications =  <ul style={{
                                color: 'white',
                                fontSize: '18px',
                                lineHeight: '26px',
                                marginTop: '20px',
                                marginBottom: '20px' }}
                                className="list-unstyled">
                                    <li>1. Bachelor's/Master's degree or equivalent experience in Business, Human Resources, or related area</li>
                                    <li>2. 5+ years' of experience in UX design</li>
                                    <li>3.Proficient in visual design and wireframing tools</li>
                                  </ul>
        
                                  let newdescriptions = <ol  style={{
                                    color: 'white',
                                    fontSize: '18px',
                                    lineHeight: '26px',
                                    marginTop: '20px',
                                    marginBottom: '20px' }}
                                    className="list-unstyled" className="list-unstyled">
                                      <li>1. Take a user-centered design approach and rapidly test and iterate your designs.</li>
                                      <li>2. Developing wireframes, flows, and prototypes based on user needs and wants</li>
                                      <li>3. Collaborating with Designers and Developers to create intuitive, user-friendly Applications (apps)</li>
                                      <li>4. Understand product specifications and user psychology</li>
                                      <li>5. Find creative ways to solve UX problems (e.g. usability, findability)</li>
                                      <li>6. Translate concepts into user flows, wireframes, mockups, and prototypes that lead to intuitive user experiences </li>
                                      <li>7. Implement the latest design innovations to ensure that the product is in accordance with cutting-edge technology.</li>
                                      <li>8. Explore various design approaches to solve specific user problems</li>
                                  </ol>
                           setJobs("Principal UX Designer (Android/iOS) ")
                           setResponsibility("As a principal UX designer, you will be responsible for building a clean and effective user experience for our users. By working cross-functionally, this candidate will understand needs from the product management, engineering, and business stakeholders and will be able to build solutions that fit those needs. ")
                           setQualifications(newqualifications);
                           setDescriptions(newdescriptions);
                           setOpen(true);
                            
                        }} 
                        className="card" style={{ width: '18rem', marginBottom: '40px', background: '#1F2A45', color: 'white' }}>
                            <div class="card-body">
                                <h5 style={{fontFamily: 'Poppins, sans-serif', fontSize: '16px', lineHeight: '20px' }} class="card-text">Principal UX Designer (Android/iOS)</h5>
                                <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: '20px', fontSize: '14px', lineHeight: '15px', color: '#95A2C2' }}>5+ years</p>
                            </div>
                        </div>
                    </div>





                    <div className="col-md-3 d-flex justify-content-center">
                        <div onClick = { ()=>{ 
                            let newqualifications =  <ul style={{
                                color: 'white',
                                fontSize: '18px',
                                lineHeight: '26px',
                                marginTop: '20px',
                                marginBottom: '20px' }}
                                className="list-unstyled">
                                    <li>1. Bachelor's/Master's degree </li>
                                    <li>2. 3+ years' Manual Tester </li>
                                    <li>3. Expertise in Manual Testing </li>
                                  </ul>
        
                                  let newdescriptions = <ol  style={{
                                    color: 'white',
                                    fontSize: '18px',
                                    lineHeight: '26px',
                                    marginTop: '20px',
                                    marginBottom: '20px' }}
                                    className="list-unstyled" className="list-unstyled">
                                      <li>1. Proficient in writing test plans and exhaustive test cases for mobile Application (Android and iOS)</li>
                                      <li>2. Expertise in Manual Testing to provide high-quality solutions</li>
                                      <li>3. Coordinating with the automation team for report verification</li>
                                      <li>4. You will be solely responsible to complete all the testing work and provide reports before every update in the Play Store</li>
                                      <li>5. Help developers to reproduce the errors and crash in the app</li>
                                      <li>6. Have enough knowledge and experience in automation tools like Selenium WebDriver(preferable) </li>
                                      <li>7. Must have experience with Firebase Crashlytics and BigQuerry</li>
                                      <li>8. Participate in Release Management</li>
                                      <li>9. Should have hands-on experience in API testing using Postman or other tools</li>
                                  </ol>
                            setOpen(true);
                            setJobs("Manual Tester - API/Mobile App Testing")
                            setResponsibility("As a principal Manual Tester, You will be solely responsible to complete all the testing work and provide reports before every update in the Play Store")
                            setQualifications(newqualifications);
                            setDescriptions(newdescriptions);
                        }} 
                        className="card" style={{ width: '18rem', marginBottom: '40px', background: '#1F2A45', color: 'white' }}>
                            <div class="card-body">
                                <h5 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', lineHeight: '16px' }} class="card-text">Manual Tester - API/Mobile App Testing</h5>
                                <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: '20px', fontSize: '14px', lineHeight: '15px', color: '#95A2C2' }}>2-4 years</p>
                            </div>
                        </div>
                    </div>





                    <div className="col-md-3 d-flex justify-content-center">
                        <div onClick = { ()=>{
                            let newqualifications =  <ul style={{
                                color: 'white',
                                fontSize: '18px',
                                lineHeight: '26px',
                                marginTop: '20px',
                                marginBottom: '20px' }}
                                className="list-unstyled">
                                    <li>1. Bachelor's/Master's degree in Computer Science </li>
                                    <li>2. 5+ years' of experience working in Android, database management,</li>
                                    <li>3. Strong logical skill </li>
                                    <li>4. Ability to hustle between different roles</li>
                                  </ul>
        
                                  let newdescriptions = <ol  style={{
                                    color: 'white',
                                    fontSize: '18px',
                                    lineHeight: '26px',
                                    marginTop: '20px',
                                    marginBottom: '20px' }}
                                    className="list-unstyled" className="list-unstyled">
                                      <li>1. Development and commercial deployment of native Android applications</li>
                                      <li>2. Review and evaluate designs and project activities for compliance with technology and development guidelines and standards</li>
                                      <li>3. Knowledge of programming patterns or architectural patterns</li>
                                      <li>4. What to consider when building an app from scratch and doing so (who is using the app - what's the intended outcome, the release date, the budget, the architecture, the 3rd party libraries, the communication between the layers, how testing will be set up, etc.)</li>
                                      <li>5. Collaborate with cross-functional teams to define, design, and ship new features</li>
                                      <li>6. Have published one or more Android apps in the app store. </li>
                                      <li>7. Demonstrated experience and an understanding of OOP, Design Patterns, Media Players, and Android memory management</li>
                                      <li>8. Experience with Unit test frameworks</li>
                                      <li>9. Experience with offline storage, threading, and performance tuning.</li>
                                      <li>10. Asynchronous task - async task, intent services, handlers, loopers, threads, runnable, loaders</li>
                                  </ol>
                            setOpen(true);
                            setJobs("Lead Android Developer")
                            setResponsibility("The ideal candidate will be responsible for handling a team of android developers, automation, and manual testers. He or she should have worked at the architect Level and launched a few apps on Google Play Store.")
                            setQualifications(newqualifications);
                            setDescriptions(newdescriptions);
                        }} 
                        className="card" style={{ width: '18rem', marginBottom: '40px', background: '#1F2A45', color: 'white' }}>
                            <div class="card-body">
                                <h5 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', lineHeight: '16px' }} class="card-text">Lead Android Developer</h5>
                                <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: '20px', fontSize: '14px', lineHeight: '15px', color: '#95A2C2' }}>5+ years</p>
                            </div>
                        </div>
                    </div>




                    <div className="col-md-3 d-flex justify-content-center">
                        <div onClick = { ()=>{
                            setOpen(true);
                            setJobs("IOS DEVELOPER")
                        }} 
                        className="card" style={{ width: '18rem', marginBottom: '40px', background: '#1F2A45', color: 'white'  }}>
                            <div class="card-body">
                                <h5 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', lineHeight: '16px' }} class="card-text">IOS DEVELOPER</h5>
                                <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: '20px', fontSize: '14px', lineHeight: '15px', color: '#95A2C2' }}>2-4 years</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center">
                        <div onClick = { ()=>{
                            setOpen(true);
                            setJobs("UX RESEARCHER")
                        }} 
                        className="card" style={{ width: '18rem', marginBottom: '40px', background: '#1F2A45', color: 'white' }}>
                            <div class="card-body">
                                <h5 style={{fontFamily: 'Poppins, sans-serif', fontSize: '16px', lineHeight: '16px' }} class="card-text">UX RESEARCHER</h5>
                                <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: '20px', fontSize: '14px', lineHeight: '15px', color: '#95A2C2' }}>2-4 years</p>
                            </div>
                        </div>
                    </div>





                    <div className="col-md-3 d-flex justify-content-center">
                        <div onClick = { ()=>{
                            setOpen(true);
                            setJobs("BACKEND DEVELOPER")
                        }} 
                        className="card" style={{ width: '18rem', marginBottom: '40px', background: '#1F2A45', color: 'white' }}>
                            <div class="card-body">
                                <h5 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', lineHeight: '16px' }} class="card-text">BACKEND DEVELOPER</h5>
                                <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: '20px', fontSize: '14px', lineHeight: '15px', color: '#95A2C2' }}>2-4 years</p>
                            </div>
                        </div>
                    </div>





                    <div className="col-md-3 d-flex justify-content-center">
                        <div onClick = { ()=>{
                            setOpen(true);
                            setJobs("ANDRIOD DEVELOPER")
                        }} 
                        className="card" style={{ width: '18rem', marginBottom: '40px', background: '#1F2A45', color: 'white' }}>
                            <div class="card-body">
                                <h5 style={{ fontFamily: 'Poppins, sans-serif', fontSize: '16px', lineHeight: '16px' }} class="card-text">ANDROID DEVELOPER</h5>
                                <p style={{ fontFamily: 'Poppins, sans-serif', marginTop: '20px', fontSize: '14px', lineHeight: '15px', color: '#95A2C2' }}>2-4 years</p>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </motion.div>
    )
}

export default Jobs;