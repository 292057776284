import React , {Component} from 'react';
import { NavLink } from 'react-router-dom';
import Aux from '../../hoc/Auxiliary';
import { motion } from "framer-motion";
import contact from '../../Assets/Images/contact.svg';
import location from '../../Assets/Images/location.svg';
import { FaArrowLeft } from "react-icons/fa";
import '../Contact/Contact.css';
import './termsConditions.css'

class gameprivacyPolicy extends Component {
    render(){
        const newcontactpolicy =
        <Aux>
            {/* <NavLink to={this.props.location.state.from}><FaArrowLeft style={{ color: 'white', size: '100px' }} /></NavLink> */}
            <NavLink to='/Contact'><FaArrowLeft style={{ color: 'white', size: '100px' }} /></NavLink>


 			<div className="row mt-5">
                <div className="col-md-8  col-sm-5 mt-5 d-flex masterdiv">
                    <div className="newcontent martc20">

                        <h1> FOOTBALL WORLD – PRIVACY POLICY </h1>

						<p>By using Audify Tech, you consent to have read, comprehended and agree to our “Privacy Policy” and “Terms of Use” provided herein.</p>
                        
						<h4>
							This Privacy Policy describes:
                    	</h4>
						<p>
						<ul>
							<li>The manners we gather personal/nonpersonal data about you and the reason we do so</li>

							<li>How we use your data</li>

							<li>The choices and rights you hold about your personal data.</li>

							<li>This Privacy Policy involves Audify Tech, websites, and related services. We may periodically revise this Privacy Policy by publishing a newer version on xygames.com. If there are any material changes made, we will inform you by posting a notice before the modification becomes effective. Your continued use of all the services after the effective date will be subject to the new Privacy Policy.</li>

						</ul>
						</p>
    
                    
                      <h4>
					  	The Data We Collect
                    </h4>
					<p>
					<ul>
							<li>Contact information (Includes name and email address)</li>

							<li>Player name and password</li>

							<li>Profile information (such as profile photo, and user id)</li>

							<li>Your messages to us (through chat logs and player support tickets)</li>

							<li>Additional data you choose to provide (e.g., information to identify a lost account)</li>

					</ul>
					</p>

					<p>Data we collect automatically.</p>

					<p>
					<ul>
							<li>Your account details and progress in the game.</li>

							<li>Your IP address and mobile device identifiers (such as your device ID, advertising ID, MAC address, and IMEI)</li>

							<li>Other data related to your device, such as name, OS, browser type, and language</li>

							<li>Data collected through cookies (on website) and similar technologies</li>

							<li>Data about your use of all services, such as gameplay data and your exchanges with other users within the product</li>

							<li>Data related to the gameplay visuals to showcase for other users to access</li>

					</ul>
					</p>
				<p>A specific user-id would also be created for each user.	</p>

					<p>
					<ul>
							<li>Data we collect from our partners (e.g., ad networks)</li>

							<li>Data we get when you link a third-party tool with our service (such as Facebook, Game Center, or Google Play Services)</li>

							<li>Demographic data (used to define the IP address location)</li>

							<li>Data collected through cookies (on website) and similar technologies</li>

							<li>Fighting fraud by using data (such as refund misuse in the game or click fraud in ads)</li>

							<li>Using data for advertising and analytics purposes, so we could provide you with a better service and experience.</li>

					</ul>	
					</p>	
   							 <h4>
								Why Do We Collect Your Data
                    		</h4> 
   							 
   							 
                        <p>To aid our services and provide a unique experience for different types of users.</p>
						
						<p>To perform the contract, we process the data which is necessary to</p>

                   		 
                    
        
                    <p>
					<ul>
					<li>Create your accounts and save your progress to make the game playing seamless</li>

					<li>To fix bugs and analytics to make the game better</li>

					<li>To connect you to play games with your friends</li>

					<li>For monetization to serve ads</li>

					<li>To enhance user experience and the gameplay</li>

					<li>Communicate all services-related points for all users</li>

					<li>To make our services more suitable for the users.</li>

					<li>Revamp and create user profiles</li>

					<li>Expand and enhance our services by improvising on user experience</li>

					<li>Manage associations, and have social features within the game</li>

					<li>Answer your comments and questions, also give technical support</li>

					<li>Share relevant information, like updates, safety alerts, and support communication</li>

					<li>Facilitate communication with other players</li>

					<li>To display personalized/non-personalized ads and notifications.</li>
					</ul>
					</p>

					<p>All sort of data collected to display personalized advertisements is in relation with -</p>
					<p>
					<ul>
						<li> Following the content you access through our services and other online behavior</li>

						<li> Enhancing our advertising content and services </li>
					</ul>
					</p>

					<p>Tracking critical data points given below helps us in keeping our services secure and fair, preventing fraudulent activities, and facilitating appropriate use -</p>
					<p>
					<ul>
						<li>Trail chats either automatically or manually</li>

						<li>Taking action against deceitful or misbehaving players</li>

						<li>To dissect, profile, and segment a set of users.</li>
					 </ul>	
					 </p>
				<p>With user permissions, we may process your data for other purposes, like using your GPS location to offer you local events and content on Playstore/Appstore.</p>
                     
							  
				<h4>Who Can See Your Data</h4>

<p>Apart from Audify Tech, your data can be accessed by other stakeholders in the following situations: </p> 

<p>Other Players and Users - Since social features are a core component of our games, other users might, for e.g, see your profile data, in-game activities and read your comments </p> 

<p>Our Partners - Audify Tech has other associates to perform services for us. These partners process your data exclusively at and in line with Audify Tech’s requirements to provide our services, like hosting, technical support, advertising, analytics, and fraud prevention. </p> 

<p>Other Businesses and Public administrations - To control fraud and illicit activities, we would swap data with other organizations and share it with public authorities in reply to lawful appeals. </p> 

<p>Audify Tech might also disclose your data, if required, to comply with the regulations, and to safeguard the rights, property, and safety of our current users, company, or others. </p> 

<p>Advertising and Media Partners - Our services allow interaction through social media and featured advertisements through partner networks. A list of these partners is available at. The network partners might access your data and operate under their privacy policies. We urge you to review their privacy policies to understand their data processing methods. </p> 

<p>International Data Transfers - All our services are present globally and so your data is transferrable across all geographies. We work on efforts towards safeguarding your data in every geography due to varied data protection laws in different countries. Other precautions that our partners may use contain legal contractual clauses backed by the EU Commission and the Privacy Shield certification in case of transfers to the USA.</p> 

<h4>GDPR Compliances</h4> 

<p>A large part of the data we process qualifies for multiple legal grounds for processing under the General Data Protection Regulation (GDPR) for EU countries. Below given are the bases for which data is processed -</p>
<p>
<ul>
	<li>We have a legitimate interest in performing analytics, measuring usage and conversions, and game telemetry to enhance our services. We and the processing team have considerable steps in place to minimize the privacy effect.</li>

<li> We have a legitimate interest in holding users’ purchase history to help users in resolving purchase and payment matters.</li>

<li>We have a legitimate interest in facilitating third-party ads and data collection on our site and applications to help provide a more customized ad experience for our users and site visitors. Our partners are flexible with opt-outs and numerous other privacy efforts to minimize the privacy impact of online ads.</li>

<li>We have a legitimate interest in recurring email and direct marketing communication, to inform registered users about updates and offers. We push transparent disclosures when users sign up and offer to opt-outs for anyone not interested in using that service.</li>
</ul>
</p>
<p>It is contractually essential to process your username, email, password, cookie data, IP address, game network IDs, game information, and similar details to satisfy our commitments in the terms of service to provide you with high-quality games and a social game network through us and our partners.</p>


<h4>Your Rights and Options</h4>

<p>You would always have right over your personal data in terms of -</p>

<p>
<ol>
<li> Obtain a copy of your data</li>

<li>Correct your data</li>

<li>Delete your account</li>

<li>Limit the use of data</li>

<li>Give and withdraw your consent</li>
</ol>
</p>

<p>All user queries would be answered within a suitable timeframe. If any query or issue regarding your information or data is unresolved, you can reach out to us through email at </p>

<p>Marketing emails and other direct channels - You can opt-out of receiving promotional communications, such as marketing emails from us by following the steps in such messages.</p>

<p>Opt-out of targeted advertising - You can opt-out of interest-based advertising on mobile applications by reviewing the privacy settings of your Android or iOS device. For any queries, Email: hello@audifytech.com</p>

<p>For personalized in-game offers opt-out, you can go to the options provided in the game settings as per your choice.</p>

<p>Cookies And Similar Technologies - Similar to most online services, we along with our partners use cookies and other technologies to provide personalized services, target advertisements, and control fraud. Cookies can be disabled in your browser settings, but then some aspects of the services may not work suitably.</p>

<p>Age Limit - We do not deliberately collect or solicit personal data for direct target interest-based advertising to children or intentionally allow such persons to use our Services. Audify Tech will not knowingly collect personal data from children without urging them to seek prior parental consent if required by law. We will only use or reveal personal data about a child to the extent permitted by law, to seek parental consent according to regional law and regulations, or to protect a child. The definition of "child" or "children" should consider applicable laws and national and regional cultural customs.</p>

<h4>HOW DO WE PROTECT YOUR DATA</h4>

<p>Security Safeguards</p>

<p>All sorts of administrative, technical, and physical security measures are being taken from our side to confirm a safe and secure user experience. These measures are taken to protect your data from any unauthorized permit.</p>

<p>Data retention - All your data and information would remain with us only until your account is active and in use. We deliberately identify and de-link all accounts which have not been in use for a considerable amount of time.</p>

<p>Also, if you ask us to clear your personal data, we will retain your data as required for our fair business interests, along with compliance with legal obligations, resolving disputes, and implementing our contracts.</p>  
							  
                            
								
								

<h4>
How to exercise your rights
</h4>
<p>You can exercise your rights  by emailing us at hello@audifytech.com</p>




                        <h4 style={{
                            marginBottom :'1px'
                        }}>
                            Thank you,
                            </h4>
                    	<h4>
                            Team Audify
                        </h4>

				</div>
                </div>
                <div className="col-md-4 mt-5 col-sm-6 masterdiv232  ">
                    <img src={contact} className="contact  img-fluid" />
                    <div className="newcontent2">
                        <h3>
                            CONTACT US
                        </h3>
                        {/* <a style={{
                                    textDecoration: 'none',
                                    color: '#1CC8EE',
                                }}>
                                    hello@audifytech.com
                        </a> */}

                        <p>Audify, Level 8, Building 3,
                                    <br />
                                     The Executive Center, Prestige Technostar, 
                                     <br />
                                     Whitefield, Bangalore , India
                                    <br />
                                    PIN - 560066,</p>
                   
                        <h3>
                            Locate us
                 		 </h3>
                        <a className="hi23" href="https://www.google.com/maps/place/Audify/@12.9740837,77.7086497,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1176c6c25ff5:0x2461cd938b4f5c0!8m2!3d12.9740825!4d77.7108343"><img className="locate22" src={location} alt="location"></img></a>
                    </div>
                </div>
			</div>
        </Aux>
        return(
            <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            {newcontactpolicy}
        </motion.div>
        );
    }
}

export default gameprivacyPolicy ;
