import React, { Component, useRef } from 'react';
import './helpNew.css';

import { motion } from "framer-motion";
import help from '../../Assets/Images/helps.png';
import Aux from '../../hoc/Auxiliary';
import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { makeStyles } from '@material-ui/core/styles';
import { BsChevronCompactRight } from "react-icons/bs";
import { Drawer } from "@material-ui/core";

import HelpDrawer from './helpDrawer.js';
import HelpMobile from './mobile/helpMobile.js';
import options from './mobile/data'



const useStyles = makeStyles((theme) => ({
  paper: {
    width: "50%",
  },
  root: {
    width: '100%',
    '& .MuiAccordion-root': {
      color: '#1F2945'
    },
    '& .MuiPaper-root': {
      backgroundColor: '#1F2945',
      color: '#fff',
      borderRadius: '25px',
      '&:hover': {
        backgroundColor: '#1CC8EE',
        color: '#FFF'
      }
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));



const HelpN = () => {


  const [check, setCheck] = useState(true);
  const [check2, setCheck2] = useState(false);
  const [checkOpen, setcheckOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchArray, setSearchArray]   = useState([]);
  const [id ,setId ] = useState(null);
  const [but1 , setBut1] = useState(true);
  const [but2 , setBut2] = useState(false);
  const [but3 , setBut3] = useState(false);
  const [click , setClick] = useState(1);
  const textInput = useRef("");

  const classes = useStyles();
  const button1 = () => {
    // console.log('hi1');
    // this.setState({ check: true, check2: false });
    setCheck(true);
    setCheck2(false);
  }

  const clickBut1 = ()=>{
    var clickchng = 1;
    setClick(clickchng);
    console.log('click = ', click);
    setBut1(true);
    setSearch("");
    textInput.current.value = "";
    afterSearch = null;
    setBut2(false);
    setBut3(false);
  }

  const clickBut2 = ()=>{
    var clickchng = 2;
    setClick(clickchng);
    console.log('click = ', click);
    setBut1(false);
    setBut2(true);
    setSearch("");
    textInput.current.value = "";
    afterSearch = null;
    setBut3(false);
  }

  const clickBut3 = ()=>{
    var clickchng = 3;
    setClick(clickchng);
    console.log('click = ', click);
    setBut1(false);
    setBut2(false);
    setSearch("");
    textInput.current.value = "";
    afterSearch = null;
    setBut3(true);
  }



  function newDrawer(id) {
    // console.log(name)
    // console.log("hi");
    setcheckOpen(true);
    setId(id);
  }



  const button2 = () => {
    // console.log('hi2');
    // this.setState({ check: false, check2: true });
    setCheck(false);
    setCheck2(true);
  }



  const changeInput = (event) => {


    var word = event.target.value;
    setSearch(word);
    const searchedArray = [];


    for (var i = 0; i < options.length; i++) {

      let j = options[i].question;
      var h = j.indexOf(word);
      if (h > -1) {

        searchedArray.push(options[i]);
      
      }

    }

    setSearchArray(searchedArray);

    console.log("word = " , word , "seach array = " , searchedArray);

  }


  var afterSearch = null;
  var beforeSearch =
  <div className="row">
  <ul>
    <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(0)} >How can I add music to the app?</li>
    <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(2)} >Can I arrange songs according to my preference ? </li>
    <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(4)} >How do I edit lyrics?</li>
    <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(6)} >Can I download music from the app?</li>
    <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(1)} >How do I prevent the app from playing call logs and whatsapp audio ? </li>
    <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(3)} >Can I remove videos at the bottom on the play screen?</li>
    <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(5)} >Can I edit song titles, etc. in the app?</li>
    <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(7)} >Can I change app language?</li>
  </ul>
</div>

  if (search !== "" && searchArray.length > 0 ) {
    afterSearch =
      <div className="row">
        <ul>
        {searchArray.map(name => (
          
            <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(name.id)}>
              {name.question}
            </li>
          
        ))}


        {/* <p style ={{color : '#1CC8EE' , margin:'20px' , position : 'absolute' , right :'0px' , bottom :'0px'}}>view all &gt;&gt;</p> */}
        
      </ul>
      </div>
  }
  
   
  
    if(click === 1)
    {
      beforeSearch =
      <div className="row">
      <ul>
        <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(0)} >How can I add music to the app?</li>
        <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(2)} >Can I arrange songs according to my preference ? </li>
        <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(4)} >How do I edit lyrics?</li>
        <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(6)} >Can I download music from the app?</li>
        <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(1)} >How do I prevent the app from playing call logs and whatsapp audio ? </li>
        <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(3)} >Can I remove videos at the bottom on the play screen?</li>
        <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(5)} >Can I edit song titles, etc. in the app?</li>
        <li className="faq_li" style={{ color: 'white' }} onClick={() => newDrawer(7)} >Can I change app language?</li>
      </ul>
    </div>
    }
   else if (click === 2){
      beforeSearch =
    <div className="row">
          <ul>
                  <li className="faq_li" style= {{ color: 'white' }}  onClick = { () => newDrawer(0) } >How can I Delete songs ?</li>
          
          
                  <li className="faq_li" style= {{ color: 'white' }}  onClick = { () => newDrawer(1) } >How can I access Drive Mode?</li>
          
          
                  <li className="faq_li" style= {{ color: 'white' }}  onClick = { () => newDrawer(2) } >How can I Edit tags?</li>
          
           
                  <li className="faq_li" style= {{ color: 'white' }}  onClick = { () => newDrawer(3) } >How can I use Device Equalizer?</li>
        
          
                  <li className="faq_li" style= {{ color: 'white' }}  onClick = { () => newDrawer(4) } >How can I mark songs as Favourites?</li>
          
          
                  <li className="faq_li" style= {{ color: 'white' }}  onClick = { () => newDrawer(5) } >Genres </li>
          
          
                  <li className="faq_li" style={{ color: 'white' }}  onClick = { () => newDrawer(6) } >How can I hide artists or albums that I don’t want to see ?</li>
          
          
                  <li className="faq_li" style={{ color: 'white' }}  onClick = { () => newDrawer(7) } >How can I Load Lyrics?</li>
          </ul>
      </div>
    }
    else if (click === 3){
      beforeSearch =
    <>
    </>
    }
    
      
  




  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <Drawer
        anchor="right"
        open={checkOpen}
        onClose={() => setcheckOpen(false)}
        classes={{ paper: classes.paper }}
      >
        <HelpDrawer checkOpen={setcheckOpen} id= {id} newDrawer = {newDrawer} />
      </Drawer>

      <Aux>
        <div className="largetdiv">
          <div  className="removediv">
            <div style = {{height : '80vh'}}  className="row mt-5">
              <div className="col-md-5  col-sm-5 mt-5 d-flex justify-content-center">
                <img src={help} className="help  img-fluid" />
              </div>
              <div className="col-md-7 mt-5 col-sm-6 justify-content-center align-self-center ">
                <div className="help-content">
                  <h1>
                    Help & faq
                        </h1>

                  <p>
                    if you have reached this page we assume that you need help from us
                            <br />
                    <br />
                            Choose the app for which you need help and search for any query
                            <br />
                            or simply browse the list of frequently asked questions (FAQs) for the
                            <br />
                            answers you are looking for.
                            <br />
                    <br />
                            if  you still can't find an answer to your question , feel free to write to us
                            <br />
                            at <a target='_blank' href = "https://mail.google.com/mail/?view=cm&fs=1&to=hello@audifytech.com" >help@audify.com</a>
                            . We'd be happy to help you out!
                    </p>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <div >
              <button onClick={button1} className={check ? 'helpbutton2' : 'helpbutton'}>
                MUSIC PLAYER
                </button>
              <button onClick={button2} style = {{display : 'none'}} className={check2 ? 'helpbutton2' : 'helpbutton'}>
                FILE BASE
                </button>
              <hr className="hrN" />
              <div className="box">
                <BsSearch style={{ color: 'white', size: '100px' }} />
                <input type="text" ref={textInput} onChange={changeInput} placeholder="Search.." className="searchbar" />
              </div>



              <div className="row ">
                <div className="col-md-4 col-sm-5 mt-5">

                 <div  className ={but1 ? "but124 d-flex" : "but123 d-flex"}>
                   <button onClick ={clickBut1}
                    className = "butindiv" >
                    Frequently Asked Questions
                    </button><BsChevronCompactRight size={28} color="white" />
                  </div>

                  


                 <div  className ={but2 ? "but124" : "but123"}>
                   <button onClick ={clickBut2}
                    className = "butindiv" >
                    TroubleshootProblems
                    </button><BsChevronCompactRight size={28} color="white" />
                  </div>


                 <div  className ={but3 ? "but124" : "but123"}>
                   <button  onClick ={clickBut3}
                    className = "butindiv" >
                    Complete Feature Guide
                    </button><BsChevronCompactRight size={28} color="white" />
                  </div>



                </div>
                {/* <div className="col-md-4 col-sm-5 mt-5 d-flex justify-content-start veticalLine" >
                  {afterSearch}
                </div> */}
                <div className="col-md-8 col-sm-8 mt-5 justify-content-center veticalLine2" >
                  {/* <div className ="row">
                  
                      <div className="col-md-6 col-sm-6 justify-content-center" style ={{margin : '0px' , padding :'0px'}} >
                      <li style ={{color : 'white'}}>hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii</li>
                      </div>
                      
                  </div> */}
                  {afterSearch?afterSearch:beforeSearch}
                </div>
              </div>

            </div>
          </div>
          <div className="remove2div">
            <HelpMobile />
          </div>
        </div>
      </Aux>
    </motion.div>
  );
                }


export default HelpN;

{/* <p>
How do I add songs to the app ?
</p>
<p>
App does not detect songs on SD card .
</p>
<h6 style={{textDecoration: 'none',color: '#1CC8EE'}}>More results related to "SONGS"</h6>
<p>
How do I block call recordings from showing 
<br />
on the app ?
</p>
<p>
How do I delete songs from the app? 
</p> */}

// <ul>
// <li>How do I add songs to the app ?</li>
// <li>App does not detect songs on SD card .</li>
// <br />
// <h6 style={{ textDecoration: 'none', color: '#1CC8EE' }}>More results related to "SONGS"</h6>
// <br />
// <li>How do I block call recordings from showing
//    <br />
//    on the app ?
// </li>
// <li>
//     How do I delete songs from the app?
// </li>
// </ul>
// </div>
// <div className="col-md-4 col-sm-5 mt-5 d-flex justify-content-start veticalLine2" >
// <ul>
// <li>I am unable to see my songs on the app</li>
// <li>Can I download music from the app</li>
// <br />
// <br />
// <li>
//     How do I edit song name ?
// </li>
// <li>
//     songs keep disappearing from my playlist
// </li>
// <h6 style={{ textDecoration: 'none', color: '#1CC8EE' }}>VIEW ALL >> </h6>
// </ul>