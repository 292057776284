import React , {Component} from 'react';
import { NavLink } from 'react-router-dom';
import Aux from '../../hoc/Auxiliary';
import { motion } from "framer-motion";
import contact from '../../Assets/Images/contact.svg';
import location from '../../Assets/Images/location.svg';
import { FaArrowLeft } from "react-icons/fa";
import '../Contact/Contact.css';
import './termsConditions.css'

class termsConditions extends Component {
    render(){
        const newcontactpolicy =
        <Aux>
            {/* <NavLink to={this.props.location.state.from}><FaArrowLeft style={{ color: 'white', size: '100px' }} /></NavLink> */}
            <NavLink to='/Contact'><FaArrowLeft style={{ color: 'white', size: '100px' }} /></NavLink>

            <div className="row mt-5">
                <div className="col-md-8  col-sm-5 mt-5 d-flex masterdiv">
                    <div className="newcontent martc20">

                        <h1>Privacy Policy &  Terms of Use</h1>


                        <h4>
                            TERMS & CONDITIONS
                    </h4>


                        <p>
                            By using Audify Music Player, you consent to have read, understood, and agree to our “Privacy Policy” and
                    
                     “Terms of Use” provided herein.
                    </p>


                        <p>
                            You are a valued user and respecting your privacy is our major concern. We do not collect any personally
                    
                    identifiable information. We do not process your personal information or disseminate it to Third Parties for any
                    
                     reason whatsoeverexcept as provided under Section 5 of this agreement. The information collected by us is
                     
                      mainly to generate logs, error reports, performance log analytics reports,crash reports, etc. which does not
                      
                       identify you personally. This information is needed to let you have the best experience with the Application. We
                       
                       do not sell or trade upon your data.
                    </p>


                        <h4>
                            1. Definitions
                    </h4>


                        <p>
                            Unless the context provides otherwise:
                    
                    1.1. Application shall mean “Audify Music Player”
                    
                    1.2. We, Our, Us, and Developer shall mean Audify Music Player
                    
                    1.3. User shall mean the person using the Application
                    
                    1.4. Storage shall mean your SD card/External Storage and internal storage of your device
                    </p>

                        <h4>
                            2. Permissions
                    </h4>


                        <p>
                            We do not collect any personal information about you. Our application needs
                    <br />
                     permission to have access to your:
                    </p>


                        <h4>
                            2.1. Storage
                    </h4>


                        <p>
                            We need access to your Storage to install the Application. Further, we need
                    
                    access to your device storage in order to add the audio files in the playlist
                    
                    available in the Application (Though, we do not store any data). You have
                    
                    options to filter out the unwanted short audio files out from the playlist and
                    
                    therefore the Application needs to have access to your storage. This
                    
                    permission also needed to install the Application in the storage device and
                    
                    save phone memory
                    </p>


                        <h4>
                            2.2. Network Communication/access and Wi-Fi:
                    </h4>


                        <p>
                            We are continuously trying to let our users have the best experience while
                    
                    using our Application. We invest a considerable amount of time fixing errors
                    
                    and improving the functionalities of the Applications. Whenever we add a
                    
                    new feature or fix an error, we intimate our users about the updates. We also
                    
                    need network details to optimize the services on slow networks
                    </p>


                        <h4>
                            2.3. Audio Settings and Reading the Contents of Your Storage
                    </h4>

                        <p>
                            The Application allows you to edit the info of the audio files such as the name
                    
                    of the audio file, details of the artist and album. You can also delete
                    
                     unwanted files using the Application. Therefore, this permission is needed.
                    </p>



                        <h4>
                            2.4. Device and App Information
                    </h4>

                        <p>
                            In order to detect the crashes and recover the Application to its state we
                    
                            need to know your device status, operating system, and its version,
                    
                            hardware model, mobile networks unique device identifier unique device
                    
                            identifier etc
                    </p>



                        <h4>
                            2.5. Device id, Phone Status
                    </h4>

                        <p>
                            All the user logs, crash reports, performance reports, analytic logs are
                    
                             mapped under unique user ids. All the users are identified by a unique id
                    
                              which is created using your device id and Phone status.
                    </p>



                        <h4>
                            2.6. Prevent phone from sleeping
                    </h4>

                        <p>
                            This is needed to keep the Application functioning as per your need. You can
                    
                             set the sleep timer to play the audios for a certain time duration. If this
                    
                             permission is not allowed the Application would stop once the phone goes to
                    
                             sleeping mode
                    </p>




                        <h4>
                            2.7. Tech details and reports
                    </h4>

                        <p>
                            Internet Protocol (IP) details, Internet Service Provider (ISP) details, operating
                    
                            system and browser details, crash report, performance logs analytics reports,
                    
                            and error reports
                    </p>



                        <h4>
                            2.8 Record Audio
                    </h4>

                        <p>
                            This permission is only required for the
                    
                             Audify voice assistant to convert the voice
                    
                              command into text so that the assistant can act on your voice
                    
                               command.
                    </p>




                        <h4>
                            2.9: Accessibility access
                    </h4>

                        <p>
                            This permission is only needed to bring the voice assistant to your home
                    
                             screen so that you can use this feature without opening the app. This
                    
                              permission is needed by the android system for such a feature
                    </p>



                        <h4>
                            2.10: Camera Permission
                    </h4>

                        <p>
                            This permission is needed to change the album art on the user’s command.
                    </p>

                        <h4>
                            2.11: Contact Permission
                    </h4>

                        <p>
                            To set the ringtone for any person in the contact list, this permission is
                    <br />
                             required.
                    </p>

                        <h4>
                            3. Cookies
                    </h4>

                        <p>
                            Cookies are small text files designed to identify your preferences and manage
                    
                             your sessions. They are transferred via web servers and are stored in your
                    
                              storage. They help the Application remember the language you have selected or
                    
                               the times you have opened the Application and user id, date, and time you have
                    
                                visited. We do not seek permission for Third-Party cookies. You can disable and
                    
                                 delete the cookies as per your convenience. However, disabling the cookies may
                    
                                  lead to the Application not functioning
                    
                                   properly
                    </p>

                        <h4>
                            4. How do we use the information
                        </h4>
                        <p>
                            To let you enjoy our services seamlessly, we are continuously putting efforts to
                    
                             bring forward the best. We may need the log reports, error reports, analytics
                    
                              reports, and performance reports for the research and development at our
                    
                               backend. We need the error reports, analytics, logs, crash reports to understand
                    
                            the issues and provide upgrades. We may also need to know the information to
                    
                             enforce our terms and conditions contained herein. We use the information in
                    
                              order to solve the User-specific queries and issues and assist you with technical
                    
                               difficulties. We use third-party companies to facilitate our service, to help us in
                    
                               analysing our app's performance and generate reports, to enhance our quality of
                    
                               service and advertisement. Here are links to the privacy policy of third-party
                    
                               library used by us
                    <br />
                            
                    <br />
                            
                               4.1. <a className = "aterms">
                            Firebase
                            </a>
                    <br />
                            
                               4.2. <a className = "aterms"> 
                        AdMob
                        </a>
                    <br />
                            
                         
                        </p>

                        <h4>
                            5. Privacy Policy
                        </h4>
                        <p>
                            
                            We reserve the right to change our Privacy Policy. You may receive
                    
                             communication from us regarding the changes. Although we have employed the
                    
                              best data protection practices, please be advised that no practice is 100 %
                    
                              secure. You are advised to keep a check on our Privacy Policy at regular
                    
                              intervals. For Privacy Policy related queries and concerns, kindly write us on
                              <br />
                    
                            <a target='_blank' href = "https://mail.google.com/mail/?view=cm&fs=1&to=hello@audifytech.com"  style={{
                            textDecoration: 'none',
                            color: '#1CC8EE',
                        }}>
                             hello@audifytech.com
                            </a>
                        </p>


                        <p>
                            Disclosure: In the event, the information, of whatever nature, is requested by
                    
                            Police or law enforcement agencies to detect the fraud, illegality, and concerns
                    
                            related to national security, we reserve the right to disclose the information
                    
                            requested.
                        </p>


                        <h4>
                            6. Terms of Use
                        </h4>
                        <p>
                            By using the Application, you agree not to share, host, display, upload, modify,
                    
                             publish, transmit, update or share any information on the Application including
                    
                              under the “Theme” section that—
                    
                              6.1. belongs to another person and to which the user does not have any right to;
                    
                              6.2. is grossly harmful, harassing, blasphemous defamatory, obscene,
                    
                              pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or
                    
                               racially, ethnically objectionable, disparaging, relating or encouraging money
                    
                               laundering or gambling, or otherwise unlawful in any manner whatever;
                    
                               6.3. harm minors in any way;
                    
                               6.4. infringes any patent, trademark, copyright, or other proprietary rights; (e)
                    
                                violates any law for the time being in force;
                    
                                6.5. deceives or misleads the addressee about the origin of such messages or
                    
                                communicates any information which is grossly offensive or menacing in
                    
                                nature;
                    
                                6.6. impersonate another person;
                    
                                6.7. contains software viruses or any other computer code, files, or programs
                    
                                 designed to interrupt, destroy or limit the functionality of any computer
                    
                                 resource;
                    
                                 6.8. threatens the unity, integrity, defence, security or sovereignty of your,
                    
                                  friendly relations with foreign states, or public order or causes incitement to
                    
                                  the commission of any cognizable offense or prevents investigation of any
                    
                                   offense or is insulting any other nation
                        </p>
								 
                        <h4>
                            7. Google user data
                        </h4>
                        <p>
                            Audify Music Player has the ability to connect your Google Drive account and manage files, 
						
							download media content located in that account. Once you connect your Google Drive account 
							
							in the application it uses OAuth access_token and official Google Drive SDK to perform 
								 
							any requests to the Google Drive server. Access_token is stored on your device 
							
							in secure system storage called Keychain. You can download your personal files 
							
							from the connected Google Drive account to your device and that files will be placed 
							
							to the App Documents directory. You can remove those files anytime using built-in 
							
							file manager in the application. You can revoke access to your Google Drive account
							
							anytime by opening account settings page on the Google Drive server from your web browser. 
							
							You can disconnect connected Google Drive account in the application and access_token 
								 
							will be also removed from your device. If you remove application from your device all 
							
							downloaded data and access_token will be also removed.
							
							Application does not share any information from your Google account. 
                        </p>
								 
                        <h4>
                            8. Google Mobile Ads
                        </h4>
                        <p>
							Audify Music Player use Google Mobile Ads SDK for monetization. To improve the performance
							
							of AdMob, the Google Mobile Ads SDK may collect certain information from apps, including:
							
							<li>IP address, which may be used to estimate the general location of a device.</li>
							
							<li>Non-user related crash logs, which may be used to diagnose problems and improve the SDK. 
							
								Diagnostic information may also be used for advertising and analytics purposes.</li>
							
							<li>User-associated performance data such as app launch time, hang rate, or energy usage, 
							
								which may be used to evaluate user behavior, understand the effectiveness of existing 
							
								product features, and plan new features. Performance data may also be used for displaying ads,
							
								including sharing with other entities that display ads.</li>
							
							<li>A Device ID such as the device&apos;s advertising identifier or other app-bounded device identifiers,
							
								which may be used for the purpose of third-party advertising and analytics</li>
							
							<li>Advertising data, such as advertisements the user has seen, may be used to power analytics
								 
								and advertising features.</li>
							
							<li>Other user product interactions like app launch taps, and interaction information, like video views,
							
								may be used to improve advertising performance.</li>
                        </p>
								 
                        <h4>
                            9. Compliance & Termination of Access
                        </h4>
                        <p>
                            Audify Music Player reserves the right to terminate your access to the
                    
                            Application and block your IP in the event we notice any contravention and/or
                    
                            you are found to be in non-compliance with the terms and conditions provided
                    
                            herein
                        </p>



                        <h4 style={{
                            marginBottom :'1px'
                        }}>
                            Thank you,
                            </h4>
                    <h4>
                            Team Audify
                        </h4>


                    </div>
                </div>
                <div className="col-md-4 mt-5 col-sm-6 masterdiv232  ">
                    <img src={contact} className="contact  img-fluid" />
                    <div className="newcontent2">
                        <h3>
                            CONTACT US
                        </h3>
                        {/* <a style={{
                                    textDecoration: 'none',
                                    color: '#1CC8EE',
                                }}>
                                    hello@audifytech.com
                        </a> */}

                        <p> Audify, Level 8, Building 3, 
                                    <br />
                                     The Executive Center, Prestige Technostar, 
                                     <br />
                                     Whitefield, Bangalore , India
                                    <br />
                                    PIN - 560066,
                   </p>
                        <h3>
                            Locate us
                  </h3>
                        <a className="hi23" href="https://www.google.com/maps/place/Audify/@12.9740837,77.7086497,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1176c6c25ff5:0x2461cd938b4f5c0!8m2!3d12.9740825!4d77.7108343"><img className="locate22" src={location} alt="location"></img></a>
                    </div>
                </div>
            </div>
        </Aux>
        return(
            <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            {newcontactpolicy}
        </motion.div>
        );
    }
}

export default termsConditions ;