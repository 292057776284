import React , {Fragment , useState} from 'react'
import { Typography, AppBar, Toolbar, Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import './Navbar.css';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DehazeIcon from '@material-ui/icons/Dehaze';


const useStyles = makeStyles((theme) => ({
    button: {
        color: 'white',
        // fontFamily: 'Luckiest Guy',
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '16px',
        "&.hover": {
            color:' #1CC8EE'
        },
        "&.active": {
            color:' #1CC8EE'
        },
      },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up("sm")]: {
                display: "flex",
            },
        },
    }));


const Navbar = () => {
    const classes = useStyles();
    const [mobileMenuAnchorE1 , setmobileMenuAnchorE1] = useState(null);
    const isMobileMenuOpen = Boolean(mobileMenuAnchorE1);

    const openMobileMenu = (event)=>{
        // console.log('hello')
        setmobileMenuAnchorE1(event.currentTarget)
    }
    const closeMobileMenu = ()=>{
        setmobileMenuAnchorE1(null)
    }

    // const style2 = {
    //     color: 'white',
    //     fontFamily: 'Luckiest Guy',
    //     fontWeight: '400',
    //     fontSize: '16px',
    //     lineHeight: '16px'
    // }


    const mobileMenu = (
        <Menu  anchorEl = {mobileMenuAnchorE1} id= "mobile-menu" keepMounted open =  {isMobileMenuOpen}>
            <MenuItem style= {{fontFamily: 'Poppins', fontWeight: '400', fontSize: '16px',lineHeight: '16px', color: 'white' }} onClick ={closeMobileMenu} component ={NavLink} to = "/">Home</MenuItem>
            <MenuItem style = {{fontFamily: 'Poppins', fontWeight: '400', fontSize: '16px',lineHeight: '16px', color: 'white' }} onClick ={closeMobileMenu} component={NavLink} to="/About">ABOUT US </MenuItem>
            {/* <MenuItem style = {{fontFamily: 'Poppins', fontWeight: '400', fontSize: '16px',lineHeight: '16px' }} onClick ={closeMobileMenu} component={NavLink} to="/Other">OTHER APPS</MenuItem> */}
            <MenuItem style = {{fontFamily: 'Poppins', fontWeight: '400', fontSize: '16px',lineHeight: '16px', color: 'white' }} onClick ={closeMobileMenu} component={NavLink} to="/Contact">CONTACT US</MenuItem>
            <MenuItem style = {{fontFamily: 'Poppins', fontWeight: '400', fontSize: '16px',lineHeight: '16px', color: 'white' }} onClick ={closeMobileMenu} component={NavLink} to="/Jobs">JOBS</MenuItem>
            <MenuItem style = {{fontFamily: 'Poppins', fontWeight: '400', fontSize: '16px',lineHeight: '16px', color: 'white' }} onClick ={closeMobileMenu} component={NavLink} to="/ourApps">OUR APPS</MenuItem>
             {/* <MenuItem style = {{fontFamily: 'Poppins', fontWeight: '400', fontSize: '16px',lineHeight: '16px', color: 'white' }} onClick ={closeMobileMenu} component={NavLink} to="/AdvertiseWithUs">Advertise With Us</MenuItem> */}
            <MenuItem style = {{fontFamily: 'Poppins', fontWeight: '400', fontSize: '16px',lineHeight: '16px', color: 'white' }} onClick ={closeMobileMenu} component={NavLink} to="/Help">Help & faq</MenuItem>


        </Menu>
    ) 

  
    return (
        <Fragment>
            <AppBar color="transparent" style={{ boxShadow: 'none' }} position = "sticky">
                <Toolbar>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>

                    </Typography>
                    <div className={classes.sectionDesktop}>
                        <Button className={classes.button} component={NavLink} exact to="/"  >Home</Button>
                        <Button className={classes.button} component={NavLink} to="/ourApps">Our apps</Button>
                         {/* <Button className={classes.button} component={NavLink} to="/AdvertiseWithUs">Advertise With Us</Button> */}
                        <Button className={classes.button} component={NavLink} to="/About">ABOUT US</Button>
                        <Button className={classes.button} component={NavLink} to="/Jobs">JOBS</Button>
                        {/* <Button style={style2} component={NavLink} to="/Other" >OTHER APPS</Button> */}
                        <Button className={classes.button}  component={NavLink} to="/Contact" >CONTACT US</Button>
                        <Button className={classes.button} component={NavLink} to="/Help">Help & faq</Button>

                    </div>
                    <IconButton style={{ color: "white" }} id ="icon" onClick={openMobileMenu}>
                        <DehazeIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {mobileMenu}
        </Fragment>
    )
}

export default Navbar;