import React, { Component } from 'react';
import { motion } from "framer-motion";
import playStore1 from '../../Assets/Images/play-store1.png';
import MusicPlayer from '../../Assets/Images/MusicPlayer.png';
import Filemgt from '../../Assets/Images/Filemgt.png';
import GameScreenCopy from '../../Assets/Images/GameScreenCopy.webp';
import upArrow from '../../Assets/Images/upArrow.png';
import playStore from '../../Assets/Images/playstore.png';
import appStore from '../../Assets/Images/appstore2.svg';
import fileManagerHalf from '../../Assets/Images/fileManagerHalf.png'
import './home.css';
import Aux from '../../hoc/Auxiliary'
import { NavLink } from 'react-router-dom'





class home extends Component {

  state = {
    rendercomp: "Games",
    rendercomp2: "Music"
  }

  render() {

    const handlebutt1 = () => {
      let temp = this.state.rendercomp
      let temp2 = this.state.rendercomp2
      this.setState({ rendercomp: temp2, rendercomp2: temp })
    }

    let consti =
      <Aux className="newaux">
        <div className="row homelength mt-5">
          <div className="col-md-5  col-sm-5 mt-5 d-flex justify-content-center">
            <img loading="lazy" src={MusicPlayer} className="playstore  img-fluid" alt='audify-musicplayer-image'/>
            <div className="d-flex justify-content-between buttondiv">
              <button onClick={handlebutt1} className="butt2">Games</button>
              <button className="butt1">Music</button>
            </div>
          </div>
          <div className="col-md-7 mt-5 col-sm-6 justify-content-center align-self-center ">
            <div className="home-content">

              <h1>
                Your music
                <br />
                in your hands
              </h1>
              <h3>
                Audify music player
              </h3>

              <p>You can listen, create playlist and more... </p>

              <h1 className="h12">
                50M+ Downloads
              </h1>

              <p><img src={upArrow} alt='check you internet connecttion' /> 10x up in last 1 year  </p>

              <div style={{ margin: '0px', padding: "0px" }} >
                <a
                  href="https://play.google.com/store/apps/details?id=com.musicplayer.playermusic"
                  target="_blank"
                >
                  <img src={playStore} alt="check you internet connection" className="buttonPlayStore" />
                </a>
                <a href="https://apps.apple.com/in/app/audify-music-player/id1565520195" target="_blank">
                  <img src={appStore} alt="check you internet connection" className="buttonAppStore" />
                </a>
              </div>
            </div>
            <NavLink to={{ pathname: "/TermsConditions", state: { from: this.props.location } }} ><button className="buttonpolicy2">
              Terms and Conditions
            </button></NavLink>

          </div>
        </div>
      </Aux>


    let consti2 =
      <Aux  >

        <div className="row homelength mt-5">
          <div className="col-md-5  col-sm-5 mt-5 d-flex justify-content-center">
            <img fetchpriority="high" src={GameScreenCopy} className="playstore  img-fluid" alt='football-world-game-image' />
            <div className="d-flex justify-content-between buttondiv">
              <button className="butt1">Games</button>
              <button onClick={handlebutt1} className="butt2">Music</button>
            </div>
          </div>
          <div className="col-md-7 mt-5 col-sm-6 justify-content-center align-self-center ">
            <div className="home-content">
              <h3>
              Audify Tech aims to leverage the power of Artificial Intelligence (AI) for building games by keeping a unique User Experience at its core
              </h3>
              <br/>

              <h2 className="h12"> First Title OUT NOW </h2>
              
              
              <h1>FOOTBALL WORLD</h1>
          
              <h5 style={{ color: '#919CB9'}}>
               Win Matches & Earn Rewards </h5>
               <br/>
              <br/> <h5 style={{ color: '#919CB9'}}>Practice & Play with Friends
              </h5>
              <br/>
              <br/> <h5 style={{ color: '#919CB9'}}>Level Up & Improve Player Attributes
              </h5>
              <div style={{ margin: '0px', padding: "0px" }} >
                <a 
                  href="https://play.google.com/store/apps/details?id=com.audify.football"
                >
                  <img src={playStore} alt="check you internet connection" className="buttonPlayStore" />
                </a>
              
              </div>
            </div>
            <NavLink to={{ pathname: "/GameTermsConditions", state: { from: this.props.location } }} ><button className="buttonpolicy2">
              Terms and Conditions
            </button></NavLink>
          </div>
        </div>
      </Aux>

    let consti3 = null;
    if (this.state.rendercomp === "Games") {
      consti3 = consti2;
    }
    else {
      consti3 = consti;
    }

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        {consti3}

      </motion.div>
    );


  }
}

export default home;