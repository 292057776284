import React from 'react';
import {Container , makeStyles } from '@material-ui/core'
import './App.css';
import {BrowserRouter, Route , Switch} from 'react-router-dom'
import Contact from './Components/Contact/Contact';
import Jobs from './Components/Jobs/Jobs';
import Navbar from './Components/NavBar/Navbar';
import Home from './Components/Home/home'
import About from './Components/AboutUs/About';
import NApps from './Components/Apps/Napps';
// import Help from './Components/Help/Help';
import Help  from './Components/HelpNew/helpNew';
import appAd from './Components/Contact/appAdvertisements';
  // import AdvertiseWithUs from './Components/AdvertiseWithUs/AdvertiseWithUs';
import termsConditions from './Components/termsConditions/termsConditions';
import gametermsConditions from './Components/termsConditions/gametermsConditions'
import gameprivacyPolicy from './Components/termsConditions/gameprivacyPolicy' 

const useStyles = makeStyles ((theme)=>({
  root:{
    width:"100vw",
    height: "100vh",
    // paddingTop : theme.spacing(5),
  },
}));

function App() {
  const classes = useStyles();
  return (
   <BrowserRouter>
    <Container className = {classes.root} disableGutters>
     <Navbar />
     <Switch>
       <Route exact path = "/" component = {Home} />
       <Route exact path = "/About" component = {About} />
       <Route exact path = "/Jobs" component = {Jobs} />
       {/* <Route exact path = "/Other" component = {Other} /> */}
       <Route exact path = "/Contact" component = {Contact} />
       <Route exact path = "/ourApps" component = {NApps} />
        {/* <Route exact path = "/AdvertiseWithUs" component = {AdvertiseWithUs} /> */}
       <Route exact path = "/Help" component = {Help} />
       <Route exact path = "/app-ads.txt" component = {appAd} />
       <Route exact path = "/TermsConditions" component = {termsConditions} />
       <Route exact path = "/GameTermsConditions" component = {gametermsConditions} />
       <Route exact path = "/GamePrivacyPolicy" component = {gameprivacyPolicy} />
     </Switch>
    </Container>
   </BrowserRouter>
  );
}

export default App;
