import React, { Component } from 'react'
import { motion } from "framer-motion";
import './Contact.css'
import contact from '../../Assets/Images/contact.svg'
import location from '../../Assets/Images/location.svg'
import Aux from '../../hoc/Auxiliary'
import { NavLink } from 'react-router-dom'

class Contact extends Component {
    render() {
        const newcontact =
            <Aux>
                <div className=" contactlength row mt-5">
                    <div className="col-md-5  col-sm-5 mt-5 d-flex justify-content-center ">
                        <img src={contact} className="contact  img-fluid" />
                    </div>
                    <div className="col-md-7 mt-5 col-sm-6 justify-content-center align-self-center ">
                        <div className="about-content2">
                            <h1>
                                CONTACT US
                            </h1>  
                            <div className ="hi2">
                            <a target='_blank' href = "https://mail.google.com/mail/?view=cm&fs=1&to=hello@audifytech.com" className = "contacta">
                                hello@audifytech.com
                            </a>
                            </div> 
                            <h6>Audify, Level 8, Building 3, The Executive Center, Prestige Technostar,
                         <br />
                       Whitefield Main Road, Bangalore - 560066, India
                       </h6>
                            <h5>
                                Locate us
                      </h5>
                            <a  target='_blank'  href="https://www.google.com/maps/place/Audify/@12.9740837,77.7086497,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1176c6c25ff5:0x2461cd938b4f5c0!8m2!3d12.9740825!4d77.7108343"><img className="locate" src={location} alt="location"></img></a>
                            <h4  className = "policyh4">Privacy policy</h4>
                            <NavLink target="_blank" to = {{ pathname: "/TermsConditions" , state: { from: this.props.location }} } ><button className = "buttonpolicy">
                                Terms and Conditions
                            </button></NavLink>
                            <br />
                            <NavLink target="_blank" to = {{ pathname: "/GamePrivacyPolicy" , state: { from: this.props.location }} } ><button className = "buttonpolicy">
                                Game Privacy Policy
                            </button></NavLink>
                            <br />
                            <NavLink target="_blank" to = {{ pathname: "/GameTermsConditions" , state: { from: this.props.location }} } ><button className = "buttonpolicy">
                                Game Terms and Conditions
                            </button></NavLink>
                          
                            </div>
                    </div>
                </div>
            </Aux>

        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
            >
                {newcontact}
            </motion.div>
        )


    }
}


export default Contact;

