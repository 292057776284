import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import { BsQuestionCircle } from "react-icons/bs";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from "@material-ui/core";
import { useState } from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BsSearch } from "react-icons/bs";
import MobileDrawer from '../helpDrawer.js';
import './helpMobile.css';
import SearchDrawer from './mobileDrawer';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
  },
  root: {
    width: '94%',
    '& .MuiAccordion-root': {
      color: '#1F2945'
    },
    '& .MuiPaper-root': {
      backgroundColor: '#1F2945',
      color: '#fff',
      borderRadius: '10px',
      margin: '20px',
      // '&:hover': {
      //   backgroundColor: '#1CC8EE',
      //   color: '#FFF'
      // }
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))



const HelpMobile = () => {
  const classes = useStyles();
  const [mobileDrawer, setmobileDrawer] = useState(false);
  const [searchDraw , setsearchDraw] = useState(false);
  const [musicButton , setmusicButton] = useState("MUSIC");
  const [id ,setId] = useState(null);

  const circlediv = (id) => {
    setId(id);
    setmobileDrawer(true);
  }
  const setItem = () =>{
    setmusicButton("MUSIC")
  }
  const setItem1 = () =>{
    setmusicButton("FILES")
  }

  const searchD = () =>{
    setsearchDraw(true);
  }
  return (
    <Aux className="auxcheck">
      <Drawer
        anchor="right"
        open={mobileDrawer}
        onClose={() => setmobileDrawer(false)}
        classes={{ paper: classes.paper }}
      >
        <MobileDrawer checkOpen={setmobileDrawer} id = {id} />
      </Drawer>

      <Drawer
        anchor="right"
        open={searchDraw}
        onClose={() => setsearchDraw(false)}
        classes={{ paper: classes.paper }}
      >
        <SearchDrawer setsearchDraw = {setsearchDraw}  />
      </Drawer>

      {/* <div className =" d-flex ">
            <h1> Help & FAQs </h1>
            <BsQuestionCircle className = "p-1" />
        </div> */}

      <div className="d-flex">
        <div className="mr-auto p-2 helpfaq">Help & FAQs</div>
        <div className="p-2 question align-self-center"><BsQuestionCircle /></div>
      </div>
      <br />
      <div className="d-flex">
        <div className="mr-auto  application ">Showing results for applications :</div>
        <div className="p-2 question align-self-center">
          <div class="dropdown">
            <button class="btn buttonToggle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {musicButton}
  </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button onClick = {setItem} class="dropdown-item" >MUSIC</button>
              {/* <button onClick = {setItem1} disabled class="dropdown-item" >FILES</button> */}
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />


      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Frequently Asked Questions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="veticalLine2">
                <ul>
                  <li onClick={() => circlediv(0)} >
                  'How do I add songs to the app ?'
                                       </li>
                  <li onClick={() => circlediv(1)} >
                  'How do I prevent the app from playing call logs and whatsapp audio?'
                                       </li>
                  <li onClick={() => circlediv(2)} >
                  'Can I arrange songs according to my preference?'
                                       </li>
                  <li onClick={() => circlediv(3)} >
                  'Can I remove videos at the bottom on the play screen?'
                                       </li>
                  <li onClick={() => circlediv(4)} >
                  'How do I edit lyrics?'
                                       </li>
                  <li onClick={() => circlediv(5)} >
                  'How do I delete songs?'
                                       </li>
                  <li onClick={() => circlediv(6)} >
                  'Can I edit song titles, etc. in the app?'
                                       </li>
                  <li onClick={() => circlediv(7)} >
                  'Can I download music from the app?'
                                       </li>
                  <li onClick={() => circlediv(8)} >
                  'Can I change app language?'
                                           </li>
                </ul>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}> Troubleshoot Problems  </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <div className="veticalLine2">
                <ul>
                  <li onClick={() => circlediv(0)} >
                  'How do I add songs to the app ?'
                                       </li>
                  <li onClick={() => circlediv(1)} >
                  'How do I prevent the app from playing call logs and whatsapp audio?'
                                       </li>
                  <li onClick={() => circlediv(2)} >
                  'Can I arrange songs according to my preference?'
                                       </li>
                  <li onClick={() => circlediv(3)} >
                  'Can I remove videos at the bottom on the play screen?'
                                       </li>
                  <li onClick={() => circlediv(4)} >
                  'How do I edit lyrics?'
                                       </li>
                  <li onClick={() => circlediv(5)} >
                  'How do I delete songs?'
                                       </li>
                  <li onClick={() => circlediv(6)} >
                  'Can I edit song titles, etc. in the app?'
                                       </li>
                  <li onClick={() => circlediv(7)} >
                  'Can I download music from the app?'
                                       </li>
                  <li onClick={() => circlediv(8)} >
                  'Can I change app language?'
                                           </li>
                </ul>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Complete Feature Guide </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            <div className="veticalLine2">
                <ul>
                  <li onClick={() => circlediv(0)} >
                  'How do I add songs to the app ?'
                                       </li>
                  <li onClick={() => circlediv(1)} >
                  'How do I prevent the app from playing call logs and whatsapp audio?'
                                       </li>
                  <li onClick={() => circlediv(2)} >
                  'Can I arrange songs according to my preference?'
                                       </li>
                  <li onClick={() => circlediv(3)} >
                  'Can I remove videos at the bottom on the play screen?'
                                       </li>
                  <li onClick={() => circlediv(4)} >
                  'How do I edit lyrics?'
                                       </li>
                  <li onClick={() => circlediv(5)} >
                  'How do I delete songs?'
                                       </li>
                  <li onClick={() => circlediv(6)} >
                  'Can I edit song titles, etc. in the app?'
                                       </li>
                  <li onClick={() => circlediv(7)} >
                  'Can I download music from the app?'
                                       </li>
                  <li onClick={() => circlediv(8)} >
                  'Can I change app language?'
                                           </li>
                </ul>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div onClick={searchD} className="makeCircle">
        <BsSearch className="searchFa" />
      </div>

    </Aux>
  );
}

export default HelpMobile;