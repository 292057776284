import React, { Component } from 'react'
import { motion } from "framer-motion";
import CountUp from 'react-countup';
import musicApps from '../../Assets/Images/musicApps.svg';
import music from '../../Assets/Images/music.png';
import GameScreenCopy from '../../Assets/Images/GameScreenCopy.webp';
import file from '../../Assets/Images/file.png';
import playStore from '../../Assets/Images/playstore.png';
import appStore from '../../Assets/Images/appstore2.svg';
import fileManager from '../../Assets/Images/filemanager.svg'
import start from "../../Assets/Images/star.png";
import Graph from '../../Assets/Images/graph2.png';
import './Napps.css';
import Aux from '../../hoc/Auxiliary'
class Napps extends Component {

    state = {
        rendercomp: "Music",
        rendercomp2: "Games"
    }


    render() {
        const handlebutt1 = () => {
            let temp = this.state.rendercomp;
            let temp2 = this.state.rendercomp2
            this.setState({ rendercomp: temp2, rendercomp2: temp })
        }

        let consti =
            <Aux className="newaux" >
                <div className="row applength ">
                    <div className="col-md-5  col-sm-5  d-flex justify-content-center">
                        <img rel="preload" src={music} className="musicApp  img-fluid" alt='audify-musicplayer-image'/>
                        <div className="d-flex justify-content-between appbuttondiv ">
                            <button onClick={handlebutt1} className="butt2">Games</button>
                            <button  className="butt1">Music</button>
                        </div>
                    </div>
                    <div className="col-md-7 mt-5 col-sm-6 justify-content-center align-self-center ">
                        <div className="myApp-content">

                            <h1>
                                Audify music player
                            </h1>
                            <h3>
                                Audify music player
                            </h3>

                            <p>You can listen, create playlist and more... </p>

                            <div className="d-flex counter1">
                                
                                <CountUp start={0} end={50} delay={0} duration={2}>
                                    {({ countUpRef }) => (
                                        <div style={{ marginRight: "20px" }}>
                                            <h3 className="mt-3" style={{ color: "#919CB9" }}>
                                                <span ref={countUpRef} />
                                                M+
                                            </h3>
                                            <h6 className="text-secondary">Downloads</h6>
                                        </div>
                                    )}
                                </CountUp>

                                <CountUp start={0} end={15} delay={0} duration={2} decimals={1}>
                                    {({ countUpRef }) => (
                                        <div style={{ marginRight: "20px" }}>
                                            <h3 className="mt-3" style={{ color: "#919CB9" }}>
                                                <span ref={countUpRef} />
                                                M+
                                            </h3>
                                            <h6 className="text-secondary">Active users</h6>
                                        </div>
                                    )}
                                </CountUp>

                                <CountUp start={0} end={12} delay={0} duration={2} decimals={1}>
                                    {({ countUpRef }) => (
                                        <div>
                                            <h3 className="mt-3" style={{ color: "#919CB9" }}>
                                                <span ref={countUpRef} />
                                                M+
                                            </h3>
                                            <h6 className="text-secondary">MAU</h6>
                                        </div>
                                    )}
                                </CountUp>
                            </div>

                            <img src={Graph} alt="please check your internet connection" className="graphimg" style={{ margin: '0px', padding: "0px", height: '226px' }} />

                            <div style={{ margin: '0px', padding: "0px" }} >
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.musicplayer.playermusic"
                                    target="_blank"
                                >
                                    <img src={playStore} alt="check you internet connection" className="buttonPlayStore" />
                                </a>
                                <a href="https://apps.apple.com/in/app/audify-music-player/id1565520195" target="_blank">
                                    <img src={appStore} alt="check you internet connection" className="buttonAppStore" />
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </Aux>


        let consti2 =
        <Aux className="newaux" >
                <div className="row applength ">
                    <div className="col-md-5  col-sm-5  d-flex justify-content-center">
                        <img rel="preload" src={GameScreenCopy} className="musicApp  img-fluid" alt='football-world-game-image'/>
                        <div className="d-flex justify-content-between appbuttondiv ">
                            <button className="butt1">Games</button>
                            <button onClick={handlebutt1}  className="butt2">Music</button>
                        </div>
                    </div>
                    <div className="col-md-7 mt-5 col-sm-6 justify-content-center align-self-center ">
                        <div className="myApp-content">

                        <h3>
                            Audify Tech aims to leverage the power of Artificial Intelligence (AI) for building games by keeping a unique User Experience at its core
                        </h3>
                        <br/>

                     <h2 style={{color:  '#919CB9'}}> First Title OUT NOW </h2>
              
              
                     <h1>FOOTBALL WORLD</h1>
          
                     <h5 style={{ color: '#919CB9'}}>
               Win Matches & Earn Rewards </h5>
               <br/>
              <br/> <h5 style={{ color: '#919CB9'}}>Practice & Play with Friends
              </h5>
              <br/>
              <br/> <h5 style={{ color: '#919CB9'}}>Level Up & Improve Player Attributes
              </h5>
                       

                            
                            <div style={{ margin: '0px', padding: "0px" }} >
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.audify.football"
                                    target="_blank"
                            >
                                    <img src={playStore} alt="check you internet connection" className="buttonPlayStore" />
                                </a>
                             
                            </div>

                        </div>
                    </div>
                </div>
            </Aux>

        let consti3 = null;
        if (this.state.rendercomp === "Games") {
            consti3 = consti;
        }
        else {
            consti3 = consti2;
        }


        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
            >
                {consti3}

            </motion.div>
        );
    }
}

export default Napps
