import React from 'react'
import './Success.css'
import bluetick from '../../Assets/Images/bluetick.svg'




const Success = ({ setFormOpen , setupClose , setOpen ,jobs }) => {
    const handle = () =>{
        setFormOpen(false);
        setupClose(false);
        setOpen(false);
    }
    return (
        <div className = "successh1">
            <img src = {bluetick} />
            <h1>
                YOU HAVE SUCCESSFULLY
                <br />
                 APPLIED FOR {jobs}
            </h1>
            <h6>
                You can sit and relax while we take it
                 from here    
            </h6>
            <div className = " check d-flex justify-content-end mt-5">
            <button onClick={handle} className="apply-btn4 " >
                DONE
           </button> 
           </div>
        </div>
    )
}

export default Success
