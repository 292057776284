import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import { Drawer } from "@material-ui/core";
import { BiCloudUpload } from "react-icons/bi";
import Newcode from './newcode';
import Success from '../Success/Success'
//Styles
import "./JobApplyForm.css";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Message from "./Message";
import Progress from "./Progress.js";
import { motion } from "framer-motion";
import { ImCross } from "react-icons/im";
//images


const useStyles = makeStyles((theme) =>
   createStyles({
      root: {
         '& .MuiChip-outlinedSecondary': {
            borderColor: "white"
         },
         '& .MuiTextField-root': {
            width: 200,
         },
         // '& .MuiDropzoneArea-root':{
         //    height:'200px',
         //    color: '#161D2F'
         // },
         '& label.Mui-focused': {
            color: '#1CC8EE',
         },
         '& label.Mui-active': {
            color: '#1CC8EE',
         },
         '& label.Mui-focused': {
            color: '#1CC8EE',
         },
         "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "white"
         },
         "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1CC8EE",
            textColor: 'white'
         },
         "& .MuiDropzoneArea-root": {
            width: '300px'
         }
      },
      TextField: {
         marginTop: "0px",
         paddingTop: "0px",
         color: "white"
      },
      input2: {
         // border:' 2px solid #1CC8EE',
         boxSizing: ' border-box',
         color: "white",
         borderRadius: '4px',
         // width: "53%",
         color: "#fff",
         // "&::placeholder": {
         //    textColor: "#fff",
         // },
      },
      input3: {
         // border:' 2px solid #1CC8EE',
         boxSizing: ' border-box',
         borderRadius: '4px',
         // width: "53%",
         color: "#fff",
         // "&::placeholder": {
         //    color: "#fff",
         // },
      },

      input: {
         // border:' 2px solid #1CC8EE',
         boxSizing: ' border-box',
         borderRadius: '4px',
         width: "350px",
         color: "#fff",
         // "&::placeholder": {
         //    // color: "#fff",
         //    textOverflow: 'ellipsis !important',
         // },

      },


      previewChip: {
         maxWidth: 100,
         minWidht: 50,
         height: 30,
         borderColor: '#1CC8EE',
         color: '#1CC8EE'
      },
   })
);

const JobApplyForm = ({ setFormOpen, jobs, setOpen }) => {
   const classes = useStyles();
   const { register, handleSubmit } = useForm();
   const [file, setFile] = useState([]);
   const [filename, setFilename] = useState("Choose File");
   const [uploadedFile, setUploadedFile] = useState(false);
   const [message, setMessage] = useState("");
   const [uploadPercentage, setUploadPercentage] = useState(0);
   const [info, setInfo] = useState({});

   const handleBlur = (e) => {
      const newInfo = { ...info };
      newInfo[e.target.name] = e.target.value;
      setInfo(newInfo);
   };

   const onChange = (e) => {
      console.log("hello")
      console.log(e.target.files);
      setFile(e.target.files);
      // setFilename(e.target.files[0].name);
   };

   // const onSubmit = (data) => JSON.stringify(data);

   /* api from environment variable =================== */
   // const BASE_URL = process.env.Locahost8000/file;


   const BASE_URL = "http://localhost:8000/api/file";
   // const BASE_URL = "localhost:8000/api/file";


   /* post request ========================*/
   const onSubmit = async (e) => {
      const formData = new FormData();
      formData.append("name", info.name);
      formData.append("email", info.email);
      formData.append("workLink", info.workLink);
      formData.append("file", file[0]);
      console.log("helloooooooo2");
      console.log(file);
      console.log(formData)

      try {
         const res = await axios.post(`${BASE_URL}/upload`, formData, {
            headers: {
               enctype: "multipart/form-data",
            },

            onUploadProgress: (progressEvent) => {
               setUploadPercentage(
                  parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
               );

               // Clear percentage
               setTimeout(() => setUploadPercentage(0), 10000);
            },
         });

         // const { fileName, filePath } = res.data;

         // setUploadedFile({ fileName, filePath });
         setMessage("Wait For Few Minutes Please")
         if (res.data.msg === "email has been sent") {
            setUploadedFile(true);
         }


      } catch (error) {
         console.log(error)
         if (error.status === 500) {
            setMessage("There was a problem with the server");
         } else {
            setMessage(error.error);
         }
      }
      // e.target.reset();
   };

   // function sendEmail(e) {
   //    e.preventDefault();

   //    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
   //      .then((result) => {
   //          console.log(result.text);
   //      }, (error) => {
   //          console.log(error.text);
   //      });
   //  }

   return (
      <motion.div
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0 }}
         transition={{ duration: 1 }}
      >
         <div className="job-apply2">
            {/* <img onClick={() => setFormOpen(false)} src={cross} alt="" /> */}
            <ImCross onClick={() => setFormOpen(false)} style={{ color: 'white', marginBottom: '20px'  , cursor : 'pointer' }} />

            {message ? <Message msg={message} /> : null}
            <h2 className="ml-2">APPLY FOR {jobs}</h2>

            <form className="job-apply-form2" autoComplete="on" onSubmit={handleSubmit(onSubmit)}>



               <div className="changewidth">
                  <TextField
                     className={classes.root}
                     id="name"
                     name="name"
                     margin="normal"
                     required
                     ref={register}
                     onChange={handleBlur}
                     label="Your name"
                     variant="outlined"
                     InputLabelProps={{
                        style: {color: '#ffffff'} 
              
                          }}
                     InputProps={{
                        className: classes.input
                     }}
                     autoFocus
                  />

                  <TextField
                     className={classes.root}
                     id="email"
                     name="email"
                     margin="normal"
                     required
                     fullWidth
                     ref={register}
                     onChange={handleBlur}
                     label="Your email address"
                     // placeholder="example@email.com"
                     variant="outlined"
                     InputLabelProps={{
                        style: {color: '#ffffff'} 
              
                          }}
                     InputProps={{
                        className: classes.input2,
                     }}
                  />
                  <TextField
                     className={classes.root}
                     id="workLink"
                     name="workLink"
                     margin="normal"
                     fullWidth
                     ref={register}
                     onChange={handleBlur}
                     label="Work link/URL"
                     placeholder="https://www.xyz.com"
                     variant="outlined"
                     InputLabelProps={{
                        style: {color: '#ffffff'} 
              
                          }}
                     InputProps={{
                        className: classes.input3,
                     }}
                  />
                  {/* <div style={{ marginTop: '25px', height: '200px' }}>
                     <DropzoneArea
                        onChange={onChange}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview
                        dropzoneText="Upload your CV/Resume "
                        previewGridProps={{ container: { spacing: 1, direction: "row" } }}
                        previewChipProps={{ classes: { root: classes.previewChip } }}
                        previewText="Selected file"
                        InputProps={{
                           className: classes.input7,
                        }}
                     />
                     <br />
                  </div> */}

                  {/* <input accept="image/*" type='file' id="raised-button-file" style={{ display: 'none' }} />
                  <label htmlFor="raised-button-file" >
                     <div className="divi">
                        <fieldset >
                           <legend>Upload CV*</legend>
                           <div className="innerElement">
                              <p>Upload Your CV/Resume</p>
                              <p className="link"> <BiCloudUpload size={40} />Click here to upload</p>
                           </div>
                        </fieldset>
                     </div>
                  </label> */}

                  <Newcode onChange = {onChange} />
            

               </div>


               {/* <Button variant="contained" component="label">
               <input
                  type="file"
                  hidden
                  onChange={onChange}
                  className="custom-file-input"
                  id="customFile"
                  InputProps={{
                     className: classes.input,
                  }}
               />
               <label className="custom-file-label" htmlFor="customFile">
                  {filename}
               </label>
            </Button>

            <Progress percentage={uploadPercentage} /> */}

               <div className="btn-area2 d-flex flex-row justify-content-end">
                  {/* <button className="cancel-btn2" onClick={() => setFormOpen(false)}>
                     Cancel
               </button> */}
                  <button className="apply-btn42" type="submit" >
                     Apply
               </button>
               </div>
            </form>
            <Drawer
               anchor="right"
               // open={uploadedFile}
               open={uploadedFile}
               onClose={() => setUploadedFile(false)}
               classes={{ paper: classes.paper }}
            >
               <Success setupClose={setUploadedFile} setFormOpen={setFormOpen} setOpen={setOpen} jobs={jobs} />
            </Drawer>

            {/* {uploadedFile ? (
               <div className="row mt-5">
                  <div className="col-md-6 m-auto">
                     <h3 className="text-center">{uploadedFile.fileName}</h3>
                     <img style={{ width: "100%" }} src={uploadedFile.filePath} alt="" />
                  </div>
               </div>
            ) : null} */}
         </div>
      </motion.div>
   );
};

export default JobApplyForm;
