import React from 'react';
import './helpDrawer.css';
import { useState } from "react";
import { FiThumbsUp } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import { FiThumbsDown } from "react-icons/fi";
import { BsQuestionCircle } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import Aux from '../../hoc/Auxiliary';
import options from './mobile/data';


const HelpDrawer =({checkOpen , id ,newDrawer })=>{

    const [cl1 , setCl1] = useState(false);
    const [cl2 , setCl2] = useState(false);

    const changeClass1 = ()=>{
        setCl1(!cl1);
        setCl2(false);
    }

    const changeClass2 =()=>{
        setCl1(false);
        setCl2(!cl2);
    }
    const cancelDrawer = () =>{
        console.log(id);
        checkOpen(false);
        // mobileDrawer(false);
    }

    return(
        <Aux >
            
        <div className ="helpdiv">
        <ImCancelCircle onClick ={cancelDrawer} size ={30} className="cancelCircle" style ={{color : 'white' , marginTop : '22px' }} />
            {/* <div className = "combinecontent">
            <ImCancelCircle onClick ={cancelDrawer} size ={30} className="cancelCircle" style ={{color : 'white' }} />
           <FiThumbsUp className = "thumbsUp"/>
           <h6 >487 people found this article helpful</h6>
        </div> */}
        
        <h1>
            {options[id].question}
        </h1>

        {options[id].answer}
        {/* <p>
            Audify automatically scans for any audio files available on your device for you to play.
            <br/>
            You can also manually search for any new audio media on your device by using the 
            <br />
            'scan media' option from the  < AiOutlineMenu />   menu  
        <br />
        </p>
        <p>
        If you cannot see any audio on your Songs section after modifications , please refresh 
        <br />
        the screen by pulling it down.
        </p>
        <p>
        You can check which folders and files on your device are getting detected and scanned 
        <br />
        by the app in the Folders tab.
        <br />
        </p> */}
        <h6>
        <br />
        Was this helpful ?
        </h6>
        <br />
        <button onClick={changeClass1} className = {cl1 ? "buttonhlp2"  : "buttonhlp"} ><FiThumbsDown style ={{marginRight :'12px' , marginLeft :'12px' , marginBottom : '4px'}} /> NO</button>
        <button onClick={changeClass2} className = {cl2 ? "buttonhlp2"  : "buttonhlp"} ><FiThumbsUp  style ={{marginRight :'12px' , marginLeft :'12px' , marginBottom : '4px'}} />YES</button> 

         <br />
        <div className= "combinecontent2">
            <BsQuestionCircle className = "QuestionCircle"  />
            <p>Didn't find what you were looking for ? Write to us at <a target='_blank' href = "https://mail.google.com/mail/?view=cm&fs=1&to=hello@audifytech.com"  style ={{ textDecoration: 'underline', color: '#1CC8EE' }}> help@audify.com </a> </p>
        </div>
        <br />
        <hr className="hrN1" />
        <h3>Related Queries</h3>
        <ul>
            <li style ={{marginBottom : '8px' , cursor : 'pointer'}} onClick={() => newDrawer(9)}>I am unable to see my songs on the app</li>
            <li  style ={{marginBottom : '10px' , cursor : 'pointer'}}  onClick={() => newDrawer(11)} >App does not detect songs on SD card </li>
            {/* <li  style ={{marginBottom : '10px'}}  onClick={() => newDrawer(7)} >Can I download music from the app</li>
            <li  style ={{marginBottom : '10px'}} onClick= { () => newDrawer(5) } >Can I edit song titles, etc. in the app?</li>
            <li  style ={{marginBottom : '10px'}} onClick= { () => newDrawer(2) } >Can I arrange songs according to my preference?</li> */}
            

        </ul>
        </div>
        </Aux>
    );
}


export default HelpDrawer ;