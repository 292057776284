import React from 'react';
import './About.css';
import { motion } from "framer-motion";
import About2 from '../../Assets/Images/About2.png';
import person1 from '../../Assets/Images/person1.png';
import person2 from '../../Assets/Images/person2.png';
import person3 from '../../Assets/Images/person3.png';
import linkedin from '../../Assets/Images/linkedin.png'


const About = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            <div className="row mt-5">
                <div className="col-md-5  col-sm-5 d-flex justify-content-center">
                    <img rel="preload" src={About2} className="aboutus  img-fluid" />
                </div>
                <div className="col-md-7  col-sm-6 justify-content-center align-self-center ">
                    <div className="about-content">
                        <h1 >About us</h1>
                        <h6>
                            We are a very productive team based out of Bengaluru
                   <br />
                   who want to make our products very easy to use . Our vision
                   <br />
                    is to make daily used apps and games experience seamless and
                    <br />
                     long lasting keeping user centricity in mind at a huge scale.
                  </h6>
                    </div>
                </div>
            </div>
            <div className="text-center about2div">
            <h1 className="my-5">Our team</h1>

            <div className="row text-center d-flex justify-content-between team">
               <div className="col-12 col-sm-4 col-md-3 Ashish">
                  <img rel="preload" src={person1} alt="" className="img-fluid" />
                  <h3>Ashish Patel</h3>
                  <a href="https://www.linkedin.com/in/patelashish010/" target="_blank">
                     <img src={linkedin} alt="" />
                  </a>
               </div>

               <div className="col-12 col-sm-4 col-md-3 mb-3 Sudhanshu">
                  <img rel="preload" src={person2} alt="" className="img-fluid" />
                  <h3>Sudhanshu Gouda</h3>
                  <a href="https://www.linkedin.com/in/sudhanshu-gouda-b1baa555/" target="_blank">
                     <img src={linkedin} alt="" />
                  </a>
               </div>

               <div className="col-12 col-sm-4 col-md-3 mb-3 Himanshu">
                  <img rel="preload" src={person3} alt="" className="img-fluid" />
                  <h3>Himanshu Gouda</h3>
                  <a href="https://www.linkedin.com/in/himanshu-gouda-49b9aa61/" target="_blank" >
                     <img src={linkedin} alt="" />
                  </a>
               </div>
            </div>
         </div>
        </motion.div>
    )
}

export default About
