import React, { Component } from 'react';
import styles from './newcode.module.css';

class newcode extends Component {
    render() {
        return (
            <React.Fragment>


                <div className={styles.uploadLabel}>
                    <div className={styles.container} >
                        <fieldset className={styles.fieldsetUpload}>
                            <legend>Upload CV*</legend>
                            <div className={styles.innerElement}>
                                <p>Upload Your CV/Resume</p>
                                <label className={styles.clickHereLable} htmlFor="raised-button-file"  >
                                    <p className={styles.link}><i className="fa fa-cloud-upload" aria-hidden="true"></i> Click here to upload</p>
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <input onChange={this.props.onChange} accept="application/pdf,application/vnd.ms-excel" type='file' id="raised-button-file" style={{ display: 'none' }} />
            </React.Fragment>
        );
    }
}
export default newcode;