import React from 'react'

import { useState } from "react";

import { Drawer, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";

import { ImCross } from "react-icons/im";

import JobApplyForm from '../JobApplyForm/JobApplyForm'

import './JobApply.css';

const useStyles = makeStyles({
    paper: {
        width: "400px",

    },
});


const Jobapply = ({ setOpen, jobs ,descriptions , qualifications , responsibility }) => {

    const [formOpen, setFormOpen] = useState(false);
    const classes = useStyles();


    return (
        <motion.div
      initial = {{opacity :  0}}
      animate = {{opacity : 1}}
      exit ={{opacity : 0}}
      transition={{ duration: 1 }}
      >
        <div className="finaljobapply" >
            <ImCross onClick={() => setOpen(false)} style = {{ color : 'white' , marginBottom: '20px' , cursor : 'pointer'}} />            

            <h1>APPLY FOR {jobs}</h1>

            <p>
                <h3 className="title">Job Description</h3>
                <span className="description">
              {responsibility}
           </span>
                <br />
                <br />
                <h3 className="title">Responsibilities:</h3>
                <br />
                <span className="description">
                    Well versed with end to end Product design including motion. Ideally, someone who is
           strong in Motion and can also do Product design</span>
                <br />
                <br />
                <span>
                    {descriptions}
                </span>
                <br />
                <span className="title">What do we expect from you?</span>
                <br />
                <span>
                   {qualifications}
                </span>
                <br />
                <span className="title">What you will get to learn and contribute</span>
                <br />
                <br />

                <span className="list-unstyled">
                    <ol>
                        <li>User research (Qualitative and Qualitative)</li>
                        <li>Entire UX Process</li>
                        <li>Collaboration with other designers</li>
                        <li>Teach us Motion Design and how to bring meaningful motion to product</li>
                    </ol>
                </span>
            </p>

            <Drawer
           anchor="right"
           open={formOpen}
           onClose={() => setFormOpen(false)}
           classes={{ paper: classes.paper }}
        >
           <JobApplyForm jobs={jobs} setFormOpen={setFormOpen} setOpen = {setOpen} />
        </Drawer>

            <div className="btn-area">
                <button onClick={() => setOpen(false)} className="cancel-btn" >
                    Cancel
           </button>
                


                <button  onClick={() => setFormOpen(true)} className="apply-btn4" >
                    Apply
           </button>

               

            </div>
        </div>
        </motion.div>
    )
}

export default Jobapply;
