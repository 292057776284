import { ImCancelCircle } from "react-icons/im";




const question0a =

 <div>
    <p>
        'Audify automatically scans for any audio files available on your device for you
    <br />
     to play. If you cannot see any audio on your Songs section, please refresh the
     <br />
     screen by pulling it down. You can also "Scan media" from the menu on the top
     <br />
     left corner to scan for any new audio media on your device.'
    </p>
</div>





const question1a =

 <div>

    <p>
        'You can block folders which you don’t want the app to scan while searching for
    <br />
    audio files on your device. In the Folders tab, use the “Block Folder” option in
    <br />
    the 3 dot menu of the folder you want to block.
    <br />
        <br />
    For example,
    <br />
    To block the Whatsapp folder from getting scanned:'
    </p>
    <p>
        <strong>Folders tab &gt; Whatsapp folder &gt; 3 dots &gt; Block this folder</strong>
    </p>
    <p>This will prevent the app from scanning for audio files in the Whatsapp folder
    <br />
    in future. However, for already present Whatsapp files, you will have to remove
    <br />
    them manually. You may use the delete option in the app. Please be cautious
    <br />
    though, as using the delete option in the app will also delete those files from
    <br />
    your device permanently.
    </p>

</div>





const question2a =

    <div>
        <p>
        Yes, but it is limited to the  currently playing Playlist.
<br />
In the play screen, open the currently playing list. Use the 3 bars before the
<br />
song title to drag the song up or down the list while pressing on it.
</p>
</div>





const question3a =

    <div>
        <p>  
        No, Audify currently does not enable you to remove the video
<br />
recommendations.
</p>
</div>





const question4a =

    <div>
        <p>
        You can edit the Lyrics by clicking on the edit icon on the top right corner. You
        <br />
         can edit the lyrics shown here or you can copy paste new lyrics here. Press the
        <br />
          save icon on the top right corner to save your changes.
        <br />
        Make sure you edit the lyrics for a song before it ends. If you make changes and
        <br />
        the next song begins, the changes will be saved to the new song lyrics and not
        <br />
        for the song you were editing.
        </p>
        </div>






const question5a =

    <div>
        <p>
        Use the "Delete" option in the 3 dots menu of the song which you wish to
<br />
delete. You can delete multiple items by long pressing on any one item. This will
<br />
activate the multi-select mode.
</p>

<p > <strong style ={{color: 'red'}} >[CAUTION !]</strong>  Deleting songs from the app will also delete the songs from your device permanently.</p>


</div>





const question6a =

    <div>
        <p>
            Yes. Click on the 3 dots for the song which you wish to edit. Select the option
            "Edit Tag". You can edit track name, artist, album and genre.
    </p>
    <p>
    NOTE :
    <li style ={{textDecoration : "none"}}>
            This feature is currently unavailable on newer Samsung devices
    </li>
        <li  style ={{textDecoration : "none"}}>
            OS 11 devices may have technical difficulties while using this feature. We
    <br />
    are working to fix it.
    </li>
    </p>
    </div>





const question7a =

    <div>
        <p>
        No, you cannot download music using the app except for the Calm music
<br />
 collection. Audify app only plays the audio files that are already present on your
<br />
 device.
 </p>
</div>





const question8a =

<div>
    <p>
Audify does not support language change as of now. App language is linked to 
<br />
your device language settings. You can access your device language through the
<br />
 device Settings &gt; System &gt; Language path.  
 </p>
</div>





const question9a =

<div>
<p>
Audify app automatically scans for any audio files available on your device for 
<br />
you to play.
<br />
If you cannot see any audio in your Songs tab, refresh the screen by pulling it
<br />
down.
<br />
You can also "Scan media" from the navigation menu (top left corner) to scan 
<br />
for any new audio media on your device.
</p>
</div>






const question10a = 

<div>
    <p>
Remove the app from the screen of apps running in the background. 
<br />
Keep the app closed when not in use by exiting the app from the notification
<br />
panel using the  <ImCancelCircle /> button.

</p>

</div>


const question11a =
<div>
    <p>
For the Audify app to detect music on SD card, the card must be installed as a
portable memory (and not as an internal memory) while installing the SD card.
<br />
</p>
<p>
Please check your SD card permissions while installing the card. You can try to
<br />
re-install the SD card and choose the option to install as portable memory so 
<br />
that the Audify app can detect the files on the card.
</p>
</div> 



const question12a = 
<div>
    <p>
Select the “Remove Ads” option in the Menu again to start using the ads-free
<br />
version.
</p>
</div>

const question13a =
<div>
    <p>
If the name of the song in your device is not correct, then lyrics shown can be 
<br />
faulty. You can try any of the following methods to get correct lyrics:
</p>
<p>
<ul >
    <li style ={{textDecoration : "none"}} >
    You can edit the Lyrics shown currently by clicking on the edit icon on
    <br />
     the top right corner.
    </li>
    <li style ={{textDecoration : "none"}} >
    You can go all the way to the bottom of the Lyrics screen and search for
    <br />
     correct lyrics using the “Find”. You can copy-paste the correct lyrics
     <br />
    from search results to the lyrics page using the method mentioned
    <br />
     above
    </li>
    <li style ={{textDecoration : "none"}} >
    You can use the “Edit Tags” option to edit the song title and generate the
    <br />
     lyrics.
    </li>
</ul>
</p>

<p>
You can go all the way to the bottom of the Lyrics screen and search for correct lyrics
<br />
 using the “Find”. You can copy-paste the correct lyrics from search results to the 
<br /> 
 lyrics page using the method mentioned above.
</p>
<p>
You can use the “Edit Tags” option to edit the song title and generate the lyrics.
</p>
</div>


const question14a = 
<div>
<p>
Check if the Audify app is allowed to run in the background. You can check the app details
<br />
from the Settings in your device under battery consumption.
</p>
<br />


<a href ="https://www.samsung.com/au/support/mobile-devices/music-stops-playing-on-one-ui/
guidingtech.com/fixes-music-stops-playing-samsung-when-phone-locks/">Reference links:</a>

</div>


const question15a = 
<div>
    <p>
You can perform the following workaround:
<ul>
    <li style ={{textDecoration : "none"}} >
    Uninstall the Audify App
    </li>
    <li style ={{textDecoration : "none"}} >
Go to any folders App (file manager)
    </li>
    <li style ={{textDecoration : "none"}} >
    Search for “com.musicplayer.playermusic”
    </li>
    <li style ={{textDecoration : "none"}} >
    Delete all files starting with “MyBits”

    </li>
    <li style ={{textDecoration : "none"}}>
    Install the Audify App again
    </li>

</ul>
</p>
</div>


// let options  = {
//     question1: {
//         question : 'How do I add songs to the app ?' ,
//         answer : question1a
//     },
//     question2: {
//         question : 'How do I prevent the app from playing call logs and whatsapp audio?' ,
//         answer : question2a
//     },
//     question3: {
//         question : 'Can I arrange songs according to my preference?' ,
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question4: {
//         question : 'Can I remove videos at the bottom on the play screen?' ,
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question5: {
//         question : 'How do I edit lyrics?' , 
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question6: {
//         question : 'How do I delete songs?' , 
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question7: {
//         question : 'Can I edit song titles, etc. in the app?',
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question8: {
//         question : 'Can I change app language?',
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question9: {
//         question : 'I am unable to see my songs on the app.',
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question10: {
//         question : 'App starts automatically.',
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question11: {
//         question : 'App does not detect SD card or USB drive.',
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question12: {
//         question : 'I see Ads even after paying for the ads-free version.',
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question13: {
//         question : 'App shows incorrect lyrics.',
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question14: {
//         question : 'App does not run in the background (app turn off when the screen locks)',
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
//     question15: {
//         question : 'App does not open and displays black (blank) screen',
//         answer :'Audify automatically scans for any audio files available on your device for you to play. If you cannot see any audio on your Songs section, please refresh the screen by pulling it down. You can also "Scan media" from the menu on the top left corner to scan for any new audio media on your device.'
//     },
// }





let options = [
    {
        id: 0,
        question: 'How do I add songs to the app ?',
        answer: question0a
    },
    {
        id: 1,
        question: 'How do I prevent the app from playing call logs and whatsapp audio?',
        answer: question1a
    },
    {
        id: 2,
        question: 'Can I arrange songs according to my preference?',
        answer: question2a
    },
    {
        id: 3,
        question: 'Can I remove videos at the bottom on the play screen?',
        answer: question3a
    },
    {
        id: 4,
        question: 'How do I edit lyrics?',
        answer: question4a
    },
    {
        id: 5,
        question: 'How do I delete songs?',
        answer: question5a,
    },
    {
        id: 6,
        question: 'Can I edit song titles, etc. in the app?',
        answer: question6a,
    },
    {
        id :7,
        question :'Can I download music from the app?',
        answer : question7a
    },
    {
        id: 8,
        question: 'Can I change app language?',
        answer: question8a,
    },
    {
        id: 9,
        question: 'I am unable to see my songs on the app.',
        answer: question9a,
    },
    {
        id: 10,
        question: 'App starts automatically.',
        answer: question10a
    },
    {
        id: 11,
        question: 'App does not detect SD card or USB drive.',
        answer: question11a
    },
    {
        id: 12,
        question: 'I see Ads even after paying for the ads-free version.',
        answer: question12a
    },
    {
        id: 13,
        question: 'App shows incorrect lyrics.',
        answer: question13a
    },
    {
        id: 14,
        question: 'App does not run in the background (app turn off when the screen locks)',
        answer: question14a
    },
    {
        id: 15,
        question: 'App does not open and displays black (blank) screen',
        answer: question15a
    }
]


export default options;