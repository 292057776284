import React from 'react';
import './mobileDrawer.css';
import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Aux from '../../../hoc/Auxiliary';
import './mobileDrawer.css';
import data from './data';

const useStyles = makeStyles((theme) => ({
    paper: {
      width: "45%",
    },
    root: {
      width: '100%',
      '& .MuiAccordion-root': {
        color: '#47557A'
      },
      '& .MuiPaper-root': {
        backgroundColor: '#47557A',
        color: '#fff',
        borderRadius: '25px',
        '&:hover': {
          backgroundColor: '#1CC8EE',
          color: '#FFF'
        }
      }
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })); 


const MobileSeprate = ({ setsearchDraw }) => {


    const [find , setFind ] = useState('');
    const [searchArray , setSearchArray] = useState([]);
    const classes = useStyles();
    

    const changeInputSearch = (event)=>{
        const searchArrayMobile = [];
        console.log(event.target.value);
        var word = event.target.value;
        setFind(word);
        
    
        for (var i = 0; i < data.length; i++) {
          let j = data[i].question;
          var h = j.indexOf(find);
          if (h > -1) {
            searchArrayMobile.push(data[i]);
          }
    
        }
        setSearchArray(searchArrayMobile);
        console.log(searchArrayMobile);
    }


    let changesSearch = null; 
    if(find !== "" && searchArray.length > 0)
    {
        console.log("yes i am in here ")
        changesSearch = 
        <div className={classes.root}>
        {searchArray.map(name => (
          <div>
               <Accordion>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
             <Typography className={classes.heading}>{name.question}</Typography>
           </AccordionSummary>
           <AccordionDetails>
             <Typography>
               {name.answer}
               <div>view article</div>
</Typography>
           </AccordionDetails>
         </Accordion>
         <br />
              </div>
         
        ))}
        {/* <p style ={{color : '#1CC8EE' , margin:'20px' , position : 'absolute' , right :'0px' , bottom :'0px'}}>view all &gt;&gt;</p> */}
   </div>
    }

    else if(find === "" && searchArray.length === 0 )
    {
        changesSearch =
        <ul className = "mobileul">
                <li>
                    add songs
                </li>
                <li>
                    download songs
                </li>
                <li>
                    delete songs 
                </li>
                <li>
                    block call logs
                </li>
                <li>
                    edit title
                </li>
                <li>
                    remove whatsapp audio
                </li>
            </ul>
    }
    else{
        changesSearch =
        <ul className = "mobileul">
                <li>
                    add songs
                </li>
                <li>
                    download songs
                </li>
                <li>
                    delete songs 
                </li>
                <li>
                    block call logs
                </li>
                <li>
                    edit title
                </li>
                <li>
                    remove whatsapp audio
                </li>
            </ul>
    }


    return (
        <Aux>
            <div className ="mobileSdiv">
            <div className="d-flex">
                <div className="mr-auto p-2 "><h6 className="mobileSh6" >Search</h6></div>
                <div className="p-2"><ImCancelCircle className="ImCancelCircle " size={30} onClick={() => setsearchDraw(false)} /></div>
            </div>
            <div className="boxnice">
                <BsSearch style={{ color: 'white', size: '100px' }} />
                <input onChange={changeInputSearch} type="text" placeholder="Search.." className="searchbar" />
            </div>
            <h5 className = "mobileh5">
                Type any keyword and start searching 
            </h5>
            <h6 className = "mobileh52">
                Frequently looked up :
            </h6> 
            <br/>
            {changesSearch}
            </div>
        </Aux>
    );
}


export default MobileSeprate;